import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { HelmetProvider } from "react-helmet-async"
import "./index.css"

import RootLayout from "./pages/Root"
import ContactUs from "./pages/ContactUs"
import CreateAccount from "./pages/CreateAccount"
import Ebhra from "./pages/Ebhra"
import EmployerCalculator from "./pages/EmployerCalculator"
import FindBroker from "./pages/FindBroker"
import Gchra from "./pages/Gchra"
import Homepage from "./pages/Homepage"
import Ichra from "./pages/Ichra"
import IchraAdp from "./pages/IchraAdp"
import IchraConsultation from "./pages/IchraConsultation"
import IchraPricing from "./pages/IchraPricing"
import IchraRules from "./pages/IchraRules"
import IchraVideoGuide from "./pages/IchraVideoGuide"
import LargeBusiness from "./pages/LargeBusinnes"
import Pricing from "./pages/Pricing"
import PrivacyPolicy from "./pages/PrivacyPolicy"
import Qsehra from "./pages/Qsehra"
import QsehraAdp from "./pages/QsehraAdp"
import QsehraConsultation from "./pages/QsehraConsultation"
import QsehraPricing from "./pages/QsehraPricing"
import QsehraRules from "./pages/QsehraRules"
import QsehraVideoGuide from "./pages/QsehraVideoGuide"
import QsehraVsIchra from "./pages/QsehraVsIchra"
import Redirect from "./pages/Redirect"
import Referral from "./pages/Referral"
import ReimbursementOptions from "./pages/ReimbursementOptions"
import TermsOfService from "./pages/TermsOfService"
import SpeakWithExpert from "./pages/SpeakWithExpert"

const router = createBrowserRouter([
	{
		path: "/",
		element: <RootLayout />,
		children: [
			{
				index: true,
				element: <Homepage />
			},
			{
				path: "*",
				element: <Redirect />
			},
			{
				path: "contact-us",
				element: <ContactUs />
			},
			{
				path: "create-account",
				element: <CreateAccount />
			},
			{
				path: "pricing",
				element: <Pricing />
			},
			{
				path: "privacy-policy",
				element: <PrivacyPolicy />
			},
			{
				path: "product/ebhra",
				element: <Ebhra />
			},
			{
				path: "product/gchra",
				element: <Gchra />
			},
			{
				path: "product/ichra",
				element: <Ichra />
			},
			{
				path: "product/ichra/adp",
				element: <IchraAdp />
			},
			{
				path: "product/ichra/compare-pricing",
				element: <IchraPricing />
			},
			{
				path: "product/ichra/consultation",
				element: <IchraConsultation />
			},
			{
				path: "product/ichra/employer-calculator",
				element: <EmployerCalculator />
			},
			{
				path: "product/ichra/find-brokers",
				element: <FindBroker />
			},
			{
				path: "product/ichra/large-business",
				element: <LargeBusiness />
			},
			{
				path: "product/ichra/reimbursement-options",
				element: <ReimbursementOptions />
			},
			{
				path: "product/ichra/rules-and-regulations",
				element: <IchraRules />
			},
			{
				path: "product/ichra/video-guide",
				element: <IchraVideoGuide />
			},
			{
				path: "product/ichra-vs-qsehra",
				element: <QsehraVsIchra />
			},
			{
				path: "product/qsehra",
				element: <Qsehra />
			},
			{
				path: "product/qsehra/adp",
				element: <QsehraAdp />
			},
			{
				path: "product/qsehra/compare-pricing",
				element: <QsehraPricing />
			},
			{
				path: "product/qsehra/consultation",
				element: <QsehraConsultation />
			},
			{
				path: "product/qsehra/rules-and-regulations",
				element: <QsehraRules />
			},
			{
				path: "product/qsehra/video-guide",
				element: <QsehraVideoGuide />
			},
			{
				path: "referral-program",
				element: <Referral />
			},
			{
				path: "terms-of-service",
				element: <TermsOfService />
			},
			{
				path: "speak-with-an-hra-expert",
				element: <SpeakWithExpert />
			}
		]
	}
])

function App() {
	return (
		<HelmetProvider>
			<RouterProvider router={router} />
		</HelmetProvider>
	)
}

export default App
