import { Helmet } from "react-helmet-async"
import ProductsHero from "../components/Products/ProductsHero"
import HraContact from "../components/Hra/HraContact"

import guideEbhra from "../assets/guide-ebhra.svg"

export default function Ebhra() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "Product",
		"name": "Best EBHRA | Simple & Affordable | Salusion",
		"description": "Salusion makes EBHRA administration easy—affordable, IRS-compliant, and hassle-free. Offer tax-free health benefits today!",
		"brand": {
			"@type": "Brand",
			"name": "Salusion"
		},
		"offers": {
			"@type": "Offer",
			"price": "5.00",
			"priceCurrency": "USD",
			"availability": "https://schema.org/InStock",
			"priceSpecification": {
				"@type": "UnitPriceSpecification",
				"price": "5.00",
				"priceCurrency": "USD",
				"billingDuration": 1,
				"billingIncrement": 1,
				"billingPeriod": "http://purl.org/goodrelations/v1#Month",
				"unitText": "participant/month",
			},
			"eligibleQuantity": {
				"@type": "QuantitativeValue",
				"value": 1,
				"unitText": "participant"
			},
			"shippingDetails": {
				"@type": "OfferShippingDetails",
				"shippingRate": {
					"@type": "MonetaryAmount",
					"value": "0.00",
					"currency": "USD"
				},
				"shippingDestination": {
					"@type": "DefinedRegion",
					"name": "United States"
				},
				"deliveryTime": {
					"@type": "QuantitativeValue",
					"value": "0",
					"unitCode": "DAY"
				}
			}
		},
		"image": "https://salusion.com/assets/images/homepage/reimbursement.svg",
		"url": "https://www.salusion.com/product/ebhra",
		"datePublished": "2024-10-05T14:30:00+00:00",
		"dateModified": "2024-10-05T14:30:00+00:00",

	}

	return (
		<>
			<Helmet>
				<title>Best EBHRA | Simple & Affordable | Salusion</title>
				<meta name="title" content="Best EBHRA | Simple & Affordable | Salusion" />
				<meta name="description" content="Salusion makes EBHRA administration easy—affordable, IRS-compliant, and hassle-free. Offer tax-free health benefits today!" />
				<meta name="keywords" content="EBHRA, tax-free benefit, simple HRA administration" />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>
			<ProductsHero
				title="Simple, Affordable EBHRAs"
				subtitle="Salusion’s EBHRA administration software helps employees cover out-of-pocket medical expenses, making health benefit management simple and cost-effective for employers."
				poster={guideEbhra}
				video="https://www.youtube.com/embed/dNix0isqGfw?si=zO81hNyHZGOkrluN"
			/>
			<div className="hra-padding-top">
				<HraContact />
			</div>
		</>
	)
}
