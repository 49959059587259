import "../components/Rules/Rules.css"

import QsehraRulesAccordion from "../components/Rules/QsehraAccordion"
import ResourceCenter from "../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../components/Resource-Center/ResourceCenterVideo"

import file from "../assets/documents/QSEHRA Rules & Regs.pdf"
import guideQsehra from "../assets/guide-salusions.svg"
import ichraVsQsehra from "../assets/ichravsqsehra-poster.svg"
import { Helmet } from "react-helmet-async"

export default function QsehraRules() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "QSEHRA Rules & Regulations",
		"author": {
			"@type": "Organization",
			"name": "Salusion"
		},
		"datePublished": "2024-10-05T14:30:00+00:00",
		"dateModified": "2024-10-05T14:30:00+00:00",
		"articleBody": "This article explains the rules and regulations governing Qualified Small Employer Health Reimbursement Arrangements (QSEHRA)."
	}

	return (
		<>
			<Helmet>
				<title>Qualified Small Employer HRA (QSEHRA) Rules | Salusion</title>
				<meta name="title" content="QSEHRA Rules & Regulations | Learn QSEHRA Compliance | Salusion" />
				<meta name="description" content="Discover everything you need to know about QSEHRA rules and regulations. Learn employer requirements, eligible employees, and key compliance considerations with Salusion’s guide." />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>

			<section id="gsehra_rules" className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>QSEHRA Rules & Regulations</h1>

					<div className="white-wrapper-all gsehra-rules-reg-wrapper">
						<h3>
							A Qualified Small Employer Health Reimbursement Arrangement “QSEHRA” is an arrangement where the employer
							reimburses employees for medical care expenses up to an annual allowance for a plan year. The
							reimbursements are generally excludable from the employee's income and wages for federal income tax and
							employment tax purposes.
						</h3>

						<a href={file} className="btn-linear" download="QSEHRA Rules & Regs.pdf">
							Download PDF
						</a>

						<div className="guide-accordion-all">
							<QsehraRulesAccordion />
						</div>
					</div>
				</div>
			</section>

			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={guideQsehra}
					title="Provide tax-advantaged benefits simply and affordably with Salusion"
					pageLink="/product/qsehra"
				/>

				<ResourceCenterVideo
					resourceImg={ichraVsQsehra}
					title="Which is right for your small business?"
					externalLink="learning-center/ichra-vs-qsehra-which-hra-is-right-for-your-business/"
				/>
			</ResourceCenter>
		</>
	)
}
