import { Helmet } from "react-helmet-async"

import "../components/Privacy/Privacy.css"

export default function TermsOfService() {
	return (
		<>
			<Helmet>
				<title>Salusion Terms of Service</title>
			</Helmet>
			<section className="first-section first-padding privacy-section">
				<div className="container">
					<div className="white-wrapper-all">
						<div className="privacy-wrapper terms-wrapper">
							<h1>Terms of Use</h1>
							<div className="privacy-wrapper-parent">
								<div className="privacy-inner-child">
									<p>
										PLEASE READ THIS TERMS OF USE AGREEMENT (THE "TERMS") CAREFULLY. THIS AGREEMENT IS A LEGAL CONTRACT
										BETWEEN YOU ("USER") AND Salusion, Inc. ("Salusion", "us", "our", and "we").
									</p>
									<p>
										SECTION 9 OF THESE TERMS IS AN ARBITRATION CLAUSE THAT REQUIRES MOST DISPUTES BETWEEN US TO BE
										RESOLVED THROUGH BINDING AND FINAL ARBITRATION INSTEAD OF IN COURT.
									</p>
									<p>
										SEE SECTION 9 FOR MORE INFORMATION REGARDING THIS ARBITRATION CLAUSE, AND HOW TO OPT OUT.BY
										ACCESSING OR USING SALUSION.COM, OR ANY OTHER WEBSITE WITH AN AUTHORIZED LINK TO THE TERMS THE
										"SITE"), INSTALLING OR USING OUR MOBILE APPLICATION ("APP"), REGISTERING AN ACCOUNT OR ACCESSING OR
										USING ANY CONTENT, INFORMATION, SERVICES, FEATURES OR RESOURCES AVAILABLE OR ENABLED VIA THE SITE OR
										APP (COLLECTIVELY, THE "SERVICES"), YOU ARE ACCEPTING THESE TERMS (ON BEHALF OF YOURSELF OR THE
										ENTITY THAT YOU REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND
										CAPACITY TO ENTER INTO THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT).
									</p>
									<p>
										Salusion reserves the right to modify the Terms or its policies relating to the Services at any
										time, effective upon posting of an updated version of these Terms or any applicable Supplemental
										Terms on the applicable Services. You should regularly review these Terms, as your continued use of
										the Services after any such changes constitutes your agreement to such changes.
									</p>
									<p>
										You acknowledge and agree that Salusion is a health savings account platform. WE DO NOT PROVIDE
										MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT, NOR ARE WE AN EMPLOYEE HEALTH OR WELFARE BENEFIT PLAN OR
										INSURANCE. WE ARE NOT SUBJECT TO THE EMPLOYEE RETIREMENT INCOME SECURITY ACT OF 1974, AS AMENDED, OR
										TO ANY STATE OR LOCAL INSURANCE LAW. WE ARE ALSO NOT A BANK OR FINANCIAL INSTITUTION, ALTHOUGH WE
										MAY PARTNER WITH BANKS AND FINANCIAL INSTITUTIONS. INFORMATION OBTAINED FROM SALUSION LICENSORS AND
										FROM THE SITE, INCLUDING TEXT, TOOLS, AND SERVICES, IS FOR INFORMATIONAL PURPOSES ONLY AND SHOULD
										NOT REPLACE PROFESSIONAL MEDICAL, FINANCIAL OR TAX ADVICE.
									</p>
								</div>
								<div class="privacy-inner-child">
									<h6>1 Use of the Services and Salusion Properties</h6>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>1.1 License</h6>
									<p>
										The Application, the Site, the Services, and the information and content available on the Site and
										in the App and the Services (as these terms are defined herein) (each, a "Salusion Property" and
										collectively, the "Salusion Properties") are protected by copyright laws throughout the world.
										Subject to these Terms, Salusion grants you a non-transferable, non-exclusive, revocable, limited
										license to use and access the Site solely for your own personal, noncommercial use.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>1.2 Application License</h6>
									<p>
										Subject to your compliance with the Terms, Salusion grants you a limited non-exclusive,
										non-transferable, non-sublicensable, revocable license to download, install and use a copy of the
										App on a single mobile device or computer that you own or control and to run such copy of the App
										solely for your own personal or internal business purposes. Furthermore, with respect to any App
										accessed through or downloaded from the Apple App Store (an "App Store Sourced App"), you will only
										use the App Store Sourced Application (a) on an Apple-branded product that runs the iOS (Apple's
										proprietary operating system) and (b) as permitted by the "Usage Rules" set forth in the Apple App
										Store Terms of Service. Notwithstanding the first sentence in this section, with respect to any
										Application access through or downloaded from the Google Play store (a "Google Play Sourced
										Application"), you may have additional license rights with respect to use of the App on a shared
										basis within your designated family group.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>1.3 Certain Restrictions</h6>
									<p>
										The rights granted to you in these Terms are subject to the following restrictions: (a) you shall
										not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially
										exploit the Site, whether in whole or in part, or any content displayed on the Site (b) you shall
										not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of
										the Site (c) you shall not access the Site in order to build a similar or competitive website,
										product, or service and (d) except as expressly stated herein, no part of the Site may be copied,
										reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by
										any means. Unless otherwise indicated, any future release, update, or other addition to
										functionality of the Site shall be subject to these Terms. All copyright and other proprietary
										notices on the Site (or on any content displayed on the Site) must be retained on all copies
										thereof.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>1.4 Modification</h6>
									<p>
										Salusion reserves the right, at any time, to modify, suspend, or discontinue the Site (in whole or
										in part) with or without notice to you. You agree that Salusion will not be liable to you or to any
										third party for any modification, suspension, or discontinuation of the Site or any part thereof.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>1.5 No Support or Maintenance</h6>
									<p>
										You acknowledge and agree that Salusion will have no obligation to provide you with any support or
										maintenance in connection with the Site.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>1.6 Ownership</h6>
									<p>
										You acknowledge that all the intellectual property rights, including copyrights, patents,
										trade-marks, and trade secrets, in the Site and its content are owned by Salusion or Salusion's
										suppliers. Neither these Terms (nor your access to the Services) transfers to you or any third party
										any rights, title or interest in or to such intellectual property rights. Salusion and its suppliers
										reserve all rights not granted in these Terms. There are no implied licenses granted under these
										Terms.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>1.7 Acceptable Use Policy</h6>
									<p>
										The following terms constitute our "Acceptable Use Policy": You agree not to: (i) upload, transmit,
										or distribute to or through the Site any computer viruses, worms, or any software intended to damage
										or alter a computer system or data (ii) send through the Site unsolicited or unauthorized
										advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other
										form of duplicative or unsolicited messages, whether commercial or otherwise (iii) use the Site to
										harvest, collect, gather or assemble information or data regarding other users, including e-mail
										addresses, without their consent (iv) interfere with, disrupt, or create an undue burden on servers
										or networks connected to the Site, or violate the regulations, policies or procedures of such
										networks (v) attempt to gain unauthorized access to the Site (or to other computer systems or
										networks connected to or used together with the Site), whether through password mining or any other
										means (vi) harass or interfere with any other user's use and enjoyment of the Site or (vi) use
										software or automated agents or scripts to produce multiple accounts on the Site, or to generate
										automated searches, requests, or queries to (or to strip, scrape, or mine data from) the Site
										(provided, however, that we conditionally grant to the operators of public search engines revocable
										permission to use spiders to copy materials from the Site for the sole purpose of and solely to the
										extent necessary for creating publicly available searchable indices of the materials, but not caches
										or archives of such materials, subject to the parameters set forth in our robots.txt file).
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>1.8 Enforcement</h6>
									<p>
										We reserve the right (but have no obligation) to monitor or review Salusion Properties and to
										investigate and/or take appropriate action against you in our sole discretion if you violate the
										Acceptable Use Policy or any other provision of these Terms or any applicable law. Such action may
										include immediately terminating your license to use Salusion Properties or your Account in
										accordance with Section 7, and/or reporting you to law enforcement authorities.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>1.9 Feedback</h6>
									<p>
										You agree that submission of any ideas, suggestions, documents, and/or proposals to Salusion through
										its suggestion, feedback, wiki, forum or similar pages ("Feedback") is at your own risk and that
										Salusion has no obligations (including without limitation obligations of confidentiality) with
										respect to such Feedback. You represent and warrant that you have all rights necessary to submit the
										Feedback. You hereby grant to Salusion a fully paid, royalty-free, perpetual, irrevocable,
										worldwide, non-exclusive, and fully sublicensable right and license to use, reproduce, perform,
										display, distribute, adapt, modify, re-format, create derivative works of, and otherwise
										commercially or non-commercially exploit in any manner, any and all Feedback, and to sublicense the
										foregoing rights, in connection with the operation and maintenance of Company Properties and/or
										Company's business.
									</p>
								</div>
								<div class="privacy-inner-child">
									<h6>2 Registration</h6>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>2.1 Registering Your Account</h6>
									<p>
										In order to access certain features of Salusion Properties you may be required to become a
										Registered User. For purposes of the Agreement, a "Registered User" is a user who has registered an
										account on the Site ("Account") or has an account with the provider of the App for the user's mobile
										device.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>2.2 Registration Data</h6>
									<p>
										In registering an account on the Site, you agree to (a) provide true, accurate, current and complete
										information about yourself as prompted by the registration form (the "Registration Data") and (b)
										maintain and promptly update the Registration Data to keep it true, accurate, current and complete.
										You represent that you are (l) at least thirteen (13) years old (m) of legal age to form a binding
										contract and (n) not a person barred from using Salusion Properties under the laws of the United
										States, your place of residence or any other applicable jurisdiction. You are responsible for all
										activities that occur under your Account. You agree that you shall monitor your Account to restrict
										use by minors, and you will accept full responsibility for any unauthorized use of Salusion
										Properties by minors. You may not share your Account or password with anyone, and you agree to (y)
										notify Salusion immediately of any unauthorized use of your password or any other breach of security
										and (z) exit from your Account at the end of each session. If you provide any information that is
										untrue, inaccurate, not current or incomplete, or Salusion has reasonable grounds to suspect that
										any information you provide is untrue, inaccurate, not current or incomplete, Salusion has the right
										to suspend or terminate your Account and refuse any and all current or future use of Salusion
										Properties (or any portion thereof). You agree not to create an Account using a false identity or
										information, or on behalf of someone other than yourself. You agree that you shall not have more
										than one Account per platform or SNS at any given time. Salusion reserves the right to remove or
										reclaim any usernames at any time and for any reason, including but not limited to, claims by a
										third party that a username violates the third party's rights. You agree not to create an Account or
										use Salusion Properties if you have been previously removed by Salusion, or if you have been
										previously banned from any of Salusion Properties.
									</p>
								</div>
								<div class="privacy-inner-child">
									<h6>3 Third-Party Links, Applications & Ads Other Users</h6>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>3.1 Third-Party Websites, Applications & Ads</h6>
									<p>
										Salusion Properties may contain links to third-party websites and services, applications and/or
										display advertisements for third parties (collectively, "Third-Party Websites, Apps & Ads"). When
										you click on a link to a Third-Party Website, Third-Party App or Third-Party Ad, we will not warn
										you that you have left Salusion Properties and are subject to the terms and conditions (including
										privacy policies) of another website or destination. Such Third-Party Links, Apps & Ads are not
										under the control of Salusion, and Salusion is not responsible for any Third-Party Websites, Apps &
										Ads. Salusion provides access to these Third-Party Websites, Apps & Ads only as a convenience to
										you, and does not review, approve, monitor, endorse, warrant, or make any representations with
										respect to Third-Party Links, Applications & Ads. You use all Third-Party Links, Applications & Ads
										at your own risk, and should apply a suitable level of caution and discretion in doing so. When you
										leave our Site, and the Terms and policies no longer govern. You should review applicable terms and
										policies, including privacy and data gathering practices, of any Third-Party websites or Third-Party
										Apps, and make whatever investigation you feel necessary or appropriate before proceeding with any
										transaction in connection with any third party.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>3.2 App Stores</h6>
									<p>
										You acknowledge and agree that the availability of the App and the Services is dependent on the
										third party from whom you received the App license, e.g., the Apple App Store or Google Play (each,
										an "App Store"). You acknowledge that the Terms are between you and Salusion and not with the App
										Store. Salusion, not the App Store, is solely responsible for Salusion Properties, including the
										App, the content thereof, maintenance, support services, and warranty therefore, and addressing any
										claims relating thereto (e.g., product liability, legal compliance or intellectual property
										infringement). In order to use the App, you must have access to a wireless network, and you agree to
										pay all fees associated with such access. You also agree to pay all fees (if any) charged by the App
										Store in connection with Salusion Properties, including the App. You agree to comply with, and your
										license to use the App is conditioned upon your compliance with all terms of agreement imposed by
										the applicable App Store when using any Salusion Property, including the App. You acknowledge that
										the App Store (and its subsidiaries) are third-party beneficiaries of the Terms and will have the
										right to enforce it.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>3.3 Release</h6>
									<p>
										You hereby release and forever discharge the Salusion (and our officers, employees, agents,
										successors, and assigns) from, and hereby waive and relinquish, each and every past, present and
										future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action
										of every kind and nature (including personal injuries, death, and property damage), that has arisen
										or arises directly or indirectly out of, or that relates directly or indirectly to, the Site
										(including any interactions with, or act or omission of, other Site users or any Third-Party Links,
										Applications & Ads). IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE
										SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: "A GENERAL RELEASE DOES NOT EXTEND TO
										CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
										EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER
										SETTLEMENT WITH THE DEBTOR."
									</p>
								</div>
								<div class="privacy-inner-child">
									<h6>4 Indemnification</h6>
									<p>
										You agree to indemnify and hold Salusion and its parents, subsidiaries, affiliates, officers,
										employees, agents, partners, suppliers and licensors (each, a "Salusion Party" and collectively, the
										"Salusion Parties") harmless from any losses, costs, liabilities and expenses (including reasonable
										attorneys' fees) relating to or arising out of any and all of the following: (a) your use of, or
										inability to use, any Salusion Property, (b) your violation of these Terms or (c) your violation of
										applicable laws or regulations. Salusion reserves the right, at your expense, to assume the
										exclusive defense and control of any matter for which you are required to indemnify us, and you
										agree to cooperate with our defense of these claims and not to settle any matter without the prior
										written consent of Salusion. Salusion will use reasonable efforts to notify you of any such claim,
										action or proceeding upon becoming aware of it. You agree that the provisions in this section will
										survive any termination of your Account, the Terms and/or your access to Salusion Properties.
									</p>
								</div>
								<div class="privacy-inner-child">
									<h6>5 Disclaimers</h6>
									<p>
										YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR USE OF
										SALUSION PROPERTIES IS AT YOUR SOLE RISK, AND SALUSION PROPERTIES ARE PROVIDED ON AN "AS-IS" AND "AS
										AVAILABLE" BASIS, AND SALUSION PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES, REPRESENTATIONS AND
										CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE
										IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT,
										ACCURACY, OR NON-INFRINGEMENT ARISING FROM USE OF THE SERVICES.
									</p>
									<p>
										SALUSION PARTIES MAKE NO WARRANTY THAT THE SALUSION PROPERTIES WILL MEET YOUR REQUIREMENTS, BE
										AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR BE ACCURATE, RELIABLE, FREE
										OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE. IF APPLICABLE LAW REQUIRES ANY
										WARRANTIES WITH RESPECT TO THE SITE, ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO NINETY (90) DAYS
										FROM THE DATE OF FIRST USE. ANY CONTENT DOWNLOADED FROM OR OTHERWISE ACCESSED THROUGH SALUSION
										PROPERTIES IS ACCESSED AT YOUR OWN RISK, AND YOU SHALL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
										PROPERTY, INLCUDING, BUT NOT LIMITED TO, YOUR COMPUTER SYSTEM AND ANY DEVICE YOU USE TO ACCESS
										SALUSION PROPERTIES, OR ANY OTHER LOST THAT RESULTS FROM ACCESSING SUCH CONTENT.
									</p>
									<p>
										THE SERVICES MAY BE SUBJECT TO DELAYS, CANCELLATIONS AND OTHER DISRUPTIONS. SALUSION MAKES NO
										WARRANTY, REPRESENTATION OR CONDITION WITH RESPECT TO THE SERVICES, INLCUDING BUT NOT LIMITED TO THE
										QUALITY, EFFECTIVENESS, REPUTATION AND OTHER CHARACTERISTICS OF SERVICES. THE SALUSION PARTIES DO
										NOT PROVIDE: (1) FINANCIAL ADVICE OR FUNCTION IN ANY WAY AS A LICENSED FINANCIAL ADVISOR (2) MEDICAL
										ADVICE OR FUNCTION IN ANY WAY AS A LICENSED DOCTOR, NURSE OR MEDICAL PRACTITIONER OR (3) TAX ADVICE
										OR FUNCTION IN ANY WAY AS A TAX ADVISOR. YOU SHOULD CONSULT WITH A LICENSED PROFESSIONAL BEFORE
										MAKING ANY FINANCIAL, MEDICAL OR TAX DECISIONS, AND YOU UNDERSTAND THAT ANY INFORMATION PROVIDED BY
										THE SALUSION PARTIES IS NOT A SUBSTITUTE FOR THE ADVICE AND COUNSEL OF SUCH LICENSED PROFESSIONALS.
									</p>
									<p>
										SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR REPRESENTATIONS, THE
										LIMITATION OR EXCLUSION OF IMPLIED WARRANTIES, OR LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY MAY
										LAST. AS SUCH, SOME OF THE ABOVE LIMITATION MAY NOT APPLY IN FULL TO YOU.
									</p>
								</div>
								<div class="privacy-inner-child">
									<h6>7 Term and Termination</h6>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>7.1 Term</h6>
									<p>
										Subject to this section, these Terms will remain in full force and effect while you use the Salusion
										Properties. Notwithstanding the foregoing, you hereby acknowledge and agree that the Terms commenced
										on the earlier to occur of (a) the date you first used Salusion Properties or (b) the date you
										accepted the Terms and will remain in full force and effect while you use any Salusion Properties,
										unless earlier terminated in accordance with the Terms. We may suspend or terminate your rights to
										use the Site (including your Account) at any time for any reason at our sole discretion, including
										for any use of the Site in violation of these Terms. If you want to terminate the Services provided
										by Salusion, you may do so by (a) notifying Salusion at any time and (b) closing your Account for
										all of the Services that you use. Your notice should be sent, in writing, to Salusion's address set
										forth:. 415 Madison Ave, 4th Floor, New York, NY 10017
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>7.2 Termination</h6>
									<p>
										Upon termination of your rights under these Terms, your Account and right to access and use the Site
										will terminate immediately. You understand that any termination of your Account may involve deletion
										of your information associated with your Account from our live databases. Salusion will not have any
										liability whatsoever to you for any termination of your rights under these Terms, including for
										termination of your Account or deletion of your account information. All provisions of the Terms
										which by their nature should survive, shall survive termination of Services, including without
										limitation, ownership provisions, warranty disclaimers, and limitation of liability.
									</p>
									<p>
										We reserve the right at any time to modify or discontinue, temporarily or permanently, Your account,
										the Website or the Application with or without notice and with or without cause. You agree that we
										shall not be liable to You or to any third party for any modification, suspension or discontinuance
										of Your account, the Website or the Application. Further, we reserve the right to suspend, close or
										deny access to Your account, the Website or the Application if we believe there is a legitimate
										reason to do so. Such reasons shall include, without limitation:
									</p>
									<ul>
										<li>Any actual or suspected breach of these Terms</li>
										<li>Any actual or suspected or actual criminal activity and</li>
										<li>Any actual or suspected misuse of, or damage to, the Website or the Application.</li>
									</ul>
									<p>
										In the event access to Your account or the Website or the Application is suspended or terminated,
										please see your HSA Deposit Account Agreement to access your HSA funds. Any suspension, modification
										or termination of your HSA will be handled in accordance with your HSA Deposit Account Agreement.
									</p>
								</div>
								<div class="privacy-inner-child">
									<h6>8 International Users</h6>
									<p>
										Salusion Properties can be accessed from countries around the world and may contain references to
										Services that are not available in your country. These references do not imply that Salusion intends
										to announce such Services in your country. Salusion Properties are controlled and offered by Company
										from its facilities in the United States of America. Salusion makes no representations that Salusion
										Properties are appropriate or available for use in other locations. Those who access or use Salusion
										Properties from other countries do so at their own volition and are responsible for compliance with
										local law.
									</p>
								</div>
								<div class="privacy-inner-child">
									<h6>9 Dispute Resolution</h6>
									<p>
										Please read the following arbitration agreement in this section ("Arbitration Agreement") carefully.
										It requires you to arbitrate most disputes with Salusion and limits the manner in which you can seek
										relief from us.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>9.1 Applicability of Arbitration Agreement</h6>
									<p>
										You agree that any dispute between you and us relating in any way to the Site or these Terms, will
										be resolved by binding arbitration, rather than in court, except that (1) you may assert claims in
										small claims court if your claims qualify, so long as the matter remains in such court and advances
										only on an individual (non-class, non-representative) basis and (2) you or Salusion may seek
										equitable relief in court for infringement or other misuse of intellectual property rights (such as
										trademarks, trade dress, domain names, trade secrets, copyrights, and patents). This Arbitration
										Agreement shall apply, without limitation, to all claims that arose or were asserted before the
										Effective Date of these Terms or any prior version of these Terms.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>9.2 Arbitration Rules and Forum</h6>
									<p>
										The Federal Arbitration Act governs the interpretation and enforcement of this Arbitration
										Agreement. To begin an arbitration proceeding, you must send a letter requesting arbitration and
										describing your claim to Salusion, Inc. c/o/ Legal, 415 Madison Avenue 4th Floor, New York, NY
										10017. The arbitration will be conducted by JAMS, an established alternative dispute resolution
										provider. Disputes involving claims and counterclaims with an amount in controversy under $250,000,
										not inclusive of attorneys' fees and interest, shall be subject to JAMS' most current version of the
										Streamlined Arbitration Rules and procedures available at
										http://www.jamsadr.com/rules-streamlined-arbitration/ all other claims shall be subject to JAMS's
										most current version of the Comprehensive Arbitration Rules and Procedures, available at
										http://www.jamsadr.com/rules-comprehensive-arbitration/. JAMS's rules are also available at
										www.jamsadr.com or by calling JAMS at 800-352-5267. If JAMS is not available to arbitrate, the
										parties will select an alternative arbitral forum. If the arbitrator finds that you cannot afford to
										pay JAMS's filing, administrative, hearing and/or other fees and you cannot obtain a waiver from
										JAMS, Salusion will pay them for you. In addition, Salusion will reimburse all such JAMS' filing,
										administrative, hearing and/or other fees for claims with an amount in controversy totaling less
										than $10,000 unless the arbitrator determines the claims are frivolous.
									</p>
									<p>
										You may choose to have the arbitration conducted by telephone, based on written submissions, or in
										person in the county where you live or at another mutually agreed location. Any judgment on the
										award rendered by the arbitrator may be entered in any court of competent jurisdiction.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>9.3 Authority of Arbitrator</h6>
									<p>
										The arbitrator, and not any federal, state or local court or agency shall have exclusive authority
										to resolve any dispute related to the interpretation, applicability, enforceability or formation of
										this Arbitration Agreement including, but not limited to any claim that all or any part of this
										Arbitration Agreement is void or voidable. The arbitrator will decide the rights and liabilities, if
										any, of you and Salusion. The arbitration proceeding will not be consolidated with any other matters
										or joined with any other proceedings or parties. The arbitrator shall have the authority to grant
										motions dispositive of all or part of any claim or dispute. The arbitrator shall have the authority
										to award monetary damages and to grant any non-monetary remedy or relief available to an individual
										under applicable law, the arbitral forum's rules, and these Terms (including the Arbitration
										Agreement). The arbitrator shall issue a written award and statement of decision describing the
										essential findings and conclusions on which any award (or decision not to render an award) is based,
										including the calculation of any damages awarded. The arbitrator shall follow the applicable law.
										The arbitrator has the same authority to award relief on an individual basis that a judge in a court
										of law would have. The award of the arbitrator is final and binding upon you and us.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>9.4 Waiver of Jury Trial</h6>
									<p>
										YOU AND SALUSION HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT (OTHER THAN
										SMALL CLAIMS COURT AS PERMITTED HEREIN) AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You and
										Salusion are instead electing that all covered claims and disputes shall be resolved by arbitration
										under this Arbitration Agreement, except as specified in section 9.1 above. An arbitrator can award
										on an individual basis the same damages and relief as a court and must follow these Terms as a court
										would. However, there is no judge or jury in arbitration, and court review of an arbitration award
										is subject to very limited review.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>9.5 Waiver of Class or Other Non-Individualized Relief</h6>
									<p>
										ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN
										INDIVIDUAL BASIS AND NOT ON A CLASS OR COLLECTIVE BASIS. ONLY INDIVIDUAL RELIEF IS AVAILABLE FOR
										CLAIMS COVERED BY THIS ARBITRATION AGREEMENT, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE
										ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If a decision is issued stating
										that applicable law precludes enforcement of any of this subsection's limitations as to a given
										claim for relief, then the claim must be severed from the arbitration and brought into the State or
										Federal Courts located in the State of New York. All other claims shall be arbitrated.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>9.6 30-Day Right to Opt Out</h6>
									<p>
										You have the right to opt out of the provisions of this Arbitration Agreement by sending a timely
										written notice of your decision to opt out to the following address: Salusion, Inc. c/o/ Legal, 415
										Madison Avenue 4th Floor, New York, NY 10017 support@salusion.com within 30 days after first
										becoming subject to this Arbitration Agreement. Your notice must include your name and address, your
										username (if any), the email address you used to set up your Account (if you have one), and a clear
										statement that you want to opt out of this Arbitration Agreement. If you opt out of this Arbitration
										Agreement, all other parts of these Terms will continue to apply to you. Opting out of this
										Arbitration Agreement hasn o effect on any other arbitration agreements that you may currently have
										with us, or may enter into in the future with us.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>9.7 Severability</h6>
									<p>
										Subject to section 10.10, if any part or parts of this Arbitration Agreement are found under the law
										to be invalid or unenforceable, then such specific part or parts shall be of no force and effect and
										shall be severed and the remainder of the Arbitration Agreement shall continue in full force and
										effect.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>9.8 Survival of Terms</h6>
									<p>
										This Arbitration Agreement will survive the termination or expiration of these Terms or your
										relationship with Salusion.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>9.9 Modification</h6>
									<p>
										Notwithstanding any provision in these Terms to the contrary, we agree that if Salusion makes any
										future material change to this Arbitration Agreement, it will not apply to any individual claim(s)
										of which you had already provided notice to Salusion.
									</p>
								</div>
								<div class="privacy-inner-child">
									<h6>10 General</h6>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>10.1 Export Control</h6>
									<p>
										You may not use, export, import, or transfer Salusion Properties except as authorized by U.S. law,
										the laws of the jurisdiction in which you obtained Salusion Properties, and any other applicable
										laws. In particular, but without limitation, Salusion Properties may not be exported or re-exported
										(a) into any United States embargoed countries, or (b) to anyone on the U.S. Treasury Department's
										list of Specially Designated Nationals or the U.S. Department of Commerce's Denied Persons List or
										Entity List. By using Company Properties, you represent and warrant that (y) you are not located in
										a country that is subject to a U.S. Government embargo, or that has been designated by the U.S.
										Government as a "terrorist supporting" country and (z) you are not listed on any U.S. Government
										list of prohibited or restricted parties. You also will not use Company Properties for any purpose
										prohibited by U.S. law, including the development, design, manufacture or production of missiles,
										nuclear, chemical or biological weapons. You acknowledge and agree that products, services or
										technology provided by Company are subject to the export control laws and regulations of the United
										States. You shall comply with these laws and regulations and shall not, without prior U.S.
										government authorization, export, reexport, or transfer Salusion products, services or technology,
										either directly or indirectly, to any country in violation of such laws and regulations.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>10.2 Electronic Communications</h6>
									<p>
										The communications between you and Salusion use electronic means, whether you use the Site or send
										us emails, or whether Salusion posts notices on the Site or communicates with you via email. For
										contractual purposes, you (a) consent to receive communications from Salusion in an electronic form
										and (b) agree that all terms and conditions, agreements, notices, disclosures, and other
										communications that Salusion provides to you electronically satisfy any legal requirement that such
										communications would satisfy if it were be in a hardcopy writing. The foregoing does not affect your
										statutory rights.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>10.3 Assignment</h6>
									<p>
										The Agreement, and your rights and obligations hereunder, may not be assigned, subcontracted,
										delegated or otherwise transferred by you without Salusion's prior written consent, and any
										attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be
										null and void.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>10.4 Exclusive Venue</h6>
									<p>
										To the extent the parties are permitted under these Terms to initiate litigation in a court, both
										you and Salusion agree that all claims and disputes arising out of or relating to these Terms will
										be litigated exclusively in the state courts in New York, New York or federal courts located in the
										Southern District of New York.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>10.5 Governing Law</h6>
									<p>
										These Terms and any action related thereto will be governed and interpreted by and under the laws of
										the State of New York, consistent with the Federal Arbitration Act, without giving effect to any
										conflict of law or other principles that provide for the application of the law of another
										jurisdiction. The United Nations Convention on Contracts for the International Sale of Goods does
										not apply to these Terms.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>10.6 Accessing and Downloading the Application from iTunes</h6>
									<p>
										The following applies to any App Store Sourced App accessed through or downloaded from the Apple App
										Store:
									</p>
									<ul>
										<li>
											You acknowledge and agree that (i) the Terms are concluded between you and Salusion only, and not
											Apple, and (ii) Salusion, not Apple, is solely responsible for the App Store Sourced App and
											content thereof. Your use of the App Store Sourced App must comply with the App Store Terms of
											Service.
										</li>
										<li>
											You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support
											services with respect to the App Store Sourced Application.
										</li>
										<li>
											In the event of any failure of the App Store Sourced App to conform to any applicable warranty,
											you may notify Apple, and Apple will refund the purchase price for the App Store Sourced App to
											you and to the maximum extent permitted by applicable law, Apple will have no other warranty
											obligation whatsoever with respect to the App Store Sourced App. As between Salusion and Apple,
											any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to
											conform to any warranty will be the sole responsibility of Salusion.
										</li>
										<li>
											You and Salusion acknowledge that, as between Salusion and Apple, Apple is not responsible for
											addressing any claims you have or any claims of any third party relating to the App Store Sourced
											App or your possession and use of the App Store Sourced App, including, but not limited to: (i)
											product liability claims (ii) any claim that the App Store Sourced App fails to conform to any
											applicable legal or regulatory requirement and (iii) claims arising under consumer protection or
											similar legislation.
										</li>
										<li>
											You and Company acknowledge that, in the event of any third-party claim that the App Store Sourced
											App or your possession and use of that App Store Sourced App infringes that third party's
											intellectual property rights, as between Salusion and Apple, Salusion, not Apple, will be solely
											responsible for the investigation, defense, settlement and discharge of any such intellectual
											property infringement claim to the extent required by the Terms.
										</li>
										<li>
											You and Salusion acknowledge and agree that Apple, and Apple's subsidiaries, are third-party
											beneficiaries of the Terms as related to your license of the App Store Sourced App, and that, upon
											your acceptance of the terms and conditions of the Terms, Apple will have the right (and will be
											deemed to have accepted the right) to enforce the Terms as related to your license of the App
											Store Sourced App against you as a third-party beneficiary thereof.
										</li>
										<li>
											Without limiting any other terms of the Terms, you must comply with all applicable third-party
											terms of agreement when using the App Store Sourced App.
										</li>
									</ul>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>10.7 Notice</h6>
									<p>
										Where Salusion requires that you provide an e-mail address, you are responsible for providing
										Salusion with your most current e-mail address. In the event that the last e-mail address you
										provided to Salusion is not valid, or for any reason is not capable of delivering to you any notices
										required/ permitted by the Agreement, Salusion's dispatch of the e-mail containing such notice will
										nonetheless constitute effective notice. You may give notice to Salusion at the following address:
										Salusion, Inc., Attn: Legal 415 Madison Ave, New York, NY 10017. Such notice shall be deemed given
										when received by Salusion by letter delivered by nationally recognized overnight delivery service or
										first class postage prepaid mail at the above address.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>10.8 Waiver</h6>
									<p>
										Any waiver or failure to enforce any provision of the Terms on one occasion will not be deemed a
										waiver of any other provision or of such provision on any other occasion.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>10.9 Severability</h6>
									<p>
										If any provision of these Terms is, for any reason, held to be invalid or unenforceable, the other
										provisions of these Terms will be unimpaired and the invalid or unenforceable provision will be
										deemed modified so that it is valid and enforceable to the maximum extent permitted by law.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>10.10 Entire Terms</h6>
									<p>
										These Terms are the final, complete and exclusive agreement of the parties with respect to the
										subject matter hereof and supersedes and merges all prior discussions between the parties with
										respect to such subject matter.
									</p>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>10.11 Representations</h6>
									<p>
										By accessing the Website and registering to use the Application, You represent that you are a
										citizen or permanent resident of the fifty United States ("U.S.") and the District of Columbia who
										is at least 18 years of age, 19 in Alabama and Nebraska, and 21 in Mississippi and Puerto Rico with
										a valid Social Security number or Tax Identification Number and You have the legal ability to agree
										to these Terms. You also agree that You will only use the Website and the Application in accordance
										with these Terms. Additional eligibility requirements apply, which four main things that determine
										whether you're eligible to have and use a health savings account:
									</p>
									<ul>
										<li>You must have a qualifying high-deductible health plan (HDHP).</li>
										<li>
											You cannot have any other health coverage, including: Medicare, Medicaid, or Tricare Other
											employer-sponsored health coverage, like an HMO or PPO Other individual coverage, like a
											short-term plan or healthshare ministry plan.
										</li>
										<li>
											You must either be a U.S. citizen or be authorized to work in the U.S., have a Social Security
											Number, and be earning income in the U.S. at the time of enrollment into a health savings account.
										</li>
										<li>
											You can't have a general health flexible spending account (FSA) with funds in it while you have an
											HSA.
										</li>
									</ul>
								</div>
								<div class="privacy-inner-child privacy-inner-child-space">
									<h6>10.12 HSA Custodial Agreement and Disclosure Statement</h6>
									<p>
										Your HSA is also governed by the terms of HSA Custodial Agreement and Disclosure Statement, which
										can be found here. Please note that Salusion is not responsible for denying or approving Your HSA
										distributions. You are solely responsible to the Internal Revenue Service ("IRS") for ensuring that
										all distributions from Your HSA are for qualified medical care expenses. For information on
										qualified medical care expenses please see IRS Publication 969 and IRS Publication 502.
									</p>
								</div>
								<div class="privacy-inner-child">
									<h6>11 Security</h6>
									<p>
										You are responsible for safeguarding Your account login information, including maintaining the
										security of Your user identification, password and other confidential information relating to Your
										HSA . If You believe Your account or login information has been compromised, contact us immediately
										by email at support@salusion.com. By accepting these Terms, You expressly agree that You will not,
										nor attempt to: (a) access data outside of the public aspects of the Website or the Application (b)
										access any nonpublic personal information relating to any other user of the Website or the
										Application (c) probe, scan or test the vulnerability of the Website or the Application or (d)
										interfere with or disrupt the Website, Application or any aspect therein, including, without
										limitation, via means of submitting a virus to the Website or Application, or overloading,
										"flooding," "spamming," "mail bombing," or "crashing" the Website. Violations of system or network
										security may result in civil or criminal liability.
									</p>
								</div>
								<div class="privacy-inner-child">
									<h6>12 Fees</h6>
									<p>
										You agree to pay all subscription, service and user fees, if any, that You are charged by Salusion
										for using the Website or Application or in connection with Your HSA, and You agree that such fees
										may be upon providing thirty (30) days prior notice to you on the Website or through the
										Application. Our current schedule of fees can be found here. You agree to pay all costs (including
										attorneys' fees), if any, incurred by Salusion in collecting overdue fees from You. You also agree
										to pay all federal, state and local taxes applicable to Your use of the Website or Application.
									</p>
								</div>
								<div class="privacy-inner-child">
									<h6>13 Links</h6>
									<p>
										Our Website may contain links to third party websites providing services and resources. We do not
										control the availability or content of such third party websites. We are not responsible for
										examining or evaluating any third party website, and we do make any representations regarding the
										quality or accuracy of the content on any such third party website. We cannot be held responsible or
										liable for the content on, or the actions of, any third party website.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
