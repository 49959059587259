import "../components/Products/Products.css"
import HraContact from "../components/Hra/HraContact"
import PriceComparison from "../components/Products/PriceComparison"
import ResourceCenter from "../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../components/Resource-Center/ResourceCenterVideo"
import { toSalusionPrivate } from "../util"
import { Link } from "react-router-dom"

import ichraVsQsehra from "../assets/ichravsqsehra-poster.svg"
import qsehraRules from "../assets/QSEHRA-Learning-Center.png"
import guideQsehra from "../assets/guide-salusions.svg"

import { PRICING_DATA } from "../components/Products/data"
import { TABLE_DATA } from "../components/Products/data"
import { Helmet } from "react-helmet-async"

export default function QsehraPricing() {
    const schema = {
        "@context": "https://schema.org",
        "@type": "ItemList",
        "itemListElement": [
            {
                "@type": "Product",
                "name": "QSEHRA Price Comparitson",
                "description": "Salusion’s QSEHRA offers the best-in-class features at the lowest prices.  Compare our pricing and discover why Salusion is the most cost-effective solution for administering your QSEHRA.",
                "image": "https://salusion.com/assets/images/homepage/reimbursement.svg",
                "brand": {
                    "@type": "Brand",
                    "name": "Salusion"
                },
                "offers": {
                    "@type": "Offer",
                    "price": "14.00",
                    "priceCurrency": "USD",
                    "availability": "https://schema.org/InStock",
                    "priceSpecification": {
                        "@type": "UnitPriceSpecification",
                        "price": "14.00",
                        "priceCurrency": "USD",
                        "billingDuration": 1,
                        "billingIncrement": 1,
                        "unitText": "participant / month",
                    },
                    "eligibleQuantity": {
                        "@type": "QuantitativeValue",
                        "value": 1,
                        "unitText": "participant"
                    },
                    "shippingDetails": {
                        "@type": "OfferShippingDetails",
                        "shippingRate": {
                            "@type": "MonetaryAmount",
                            "value": "0.00",
                            "currency": "USD"
                        },
                        "shippingDestination": {
                            "@type": "DefinedRegion",
                            "name": "United States"
                        },
                        "deliveryTime": {
                            "@type": "QuantitativeValue",
                            "value": "0",
                            "unitCode": "DAY"
                        }
                    }
                },
            },
            {
                "@type": "Product",
                "name": "Salusion ICHRA",
                "description": "Salusion’s QSEHRA offers the best-in-class features at the lowest prices.",
                "brand": {
                    "@type": "Brand",
                    "name": "Take Command"
                },
                "offers": {
                    "@type": "Offer",
                    "availability": "https://schema.org/InStock",
                    "priceCurrency": "USD",
                    "priceSpecification": [
                        {
                            "@type": "UnitPriceSpecification",
                            "price": "20.00",
                            "priceCurrency": "USD",
                            "unitText": "person/month",
                            "billingDuration": "1",
                            "billingPeriod": "http://purl.org/goodrelations/v1#Month"
                        },
                        {
                            "@type": "PriceSpecification",
                            "price": "40.00",
                            "priceCurrency": "USD",
                            "description": "Platform fee",
                            "billingDuration": "1",
                            "billingPeriod": "http://purl.org/goodrelations/v1#Month"
                        }
                    ],
                    "shippingDetails": {
                        "@type": "OfferShippingDetails",
                        "shippingRate": {
                            "@type": "MonetaryAmount",
                            "value": "0.00",
                            "currency": "USD"
                        },
                        "shippingDestination": {
                            "@type": "DefinedRegion",
                            "name": "United States"
                        },
                        "deliveryTime": {
                            "@type": "QuantitativeValue",
                            "value": "0",
                            "unitCode": "DAY"
                        }
                    }
                },
            }, {
                "@type": "Product",
                "name": "Salusion ICHRA",
                "description": "Salusion’s QSEHRA offers the best-in-class features at the lowest prices.",
                "brand": {
                    "@type": "Brand",
                    "name": "PeopleKeep"
                },
                "offers": {
                    "@type": "Offer",
                    "priceCurrency": "USD",
                    "availability": "https://schema.org/InStock",
                    "priceSpecification": [
                        {
                            "@type": "UnitPriceSpecification",
                            "price": "19.00",
                            "priceCurrency": "USD",
                            "unitText": "person/month",
                            "billingDuration": "1",
                            "billingPeriod": "http://purl.org/goodrelations/v1#Month"
                        },
                        {
                            "@type": "PriceSpecification",
                            "price": "35.00",
                            "priceCurrency": "USD",
                            "description": "Platform fee",
                            "billingDuration": "1",
                            "billingPeriod": "http://purl.org/goodrelations/v1#Month"
                        },
                        {
                            "@type": "PriceSpecification",
                            "price": "150.00",
                            "priceCurrency": "USD",
                            "description": "One-time setup fee",
                            "billingDuration": "1",
                            "billingPeriod": "http://purl.org/goodrelations/v1#BillingPeriodUnspecified"
                        }
                    ],
                    "shippingDetails": {
                        "@type": "OfferShippingDetails",
                        "shippingRate": {
                            "@type": "MonetaryAmount",
                            "value": "0.00",
                            "currency": "USD"
                        },
                        "shippingDestination": {
                            "@type": "DefinedRegion",
                            "name": "United States"
                        },
                        "deliveryTime": {
                            "@type": "QuantitativeValue",
                            "value": "0",
                            "unitCode": "DAY"
                        }
                    }
                },
            },
        ]
    }
    function scrollToContact() {
        document.getElementById("start_hra").scrollIntoView({ behavior: "smooth" })
    }

    return (
        <>
            <Helmet>
                <title>Qualified Small Employer HRA (QSEHRA) Pricing | Salusion</title>
                <meta name="title" content="Compare QSEHRA Pricing: Best Features at the Lowest Prices | Salusion" />
                <meta name="description" content="See how Salusion’s QSEHRA offers the best-in-class features at the lowest prices. Compare our pricing and discover why Salusion is the most cost-effective solution for administering your QSEHRA." />
                <script type="application/ld+json">{JSON.stringify(schema)}</script>
            </Helmet>

            <section id="gsehra_rules" className="first-section-full-background first-padding rules-all">
                <div className="container">
                    <div className="salusion-guide-wrapper">
                        <h1>QSEHRA Price Comparison</h1>
                        <p>Salusion’s ICHRA offers the best-in-class features at the lowest prices.  Compare our pricing and discover why Salusion is the most cost-effective solution for administering your ICHRA.</p>
                        <div className="salusion-guide-btn-wrapper">
                            <Link onClick={(event) => toSalusionPrivate(event, "/create-account", false)}>
                                Start Your Company's HRA
                            </Link>

                            <Link onClick={scrollToContact}>Speak with an Expert</Link>
                        </div>
                        <br />
                        <br />
                        <div className="products-hra-wrapper">
                            <PriceComparison dataPricing={PRICING_DATA} dataCheckmark={TABLE_DATA} title="QSEHRA Price Comparison" />
                        </div>
                    </div>
                </div>

            </section>
            <ResourceCenter>
                <ResourceCenterVideo
                    resourceImg={guideQsehra}
                    title="Provide tax-advantaged benefits simply and affordably with Salusion"
                    pageLink="/product/qsehra/video-guide"
                />
				<ResourceCenterVideo
					resourceImg={ichraVsQsehra}
					title="Which is right for your small business?"
					externalLink="learning-center/ichra-vs-qsehra-which-hra-is-right-for-your-business/"
				/>

                <ResourceCenterVideo
                    resourceImg={qsehraRules}
                    title="Learn what QSEHRAs do, how they work, and how to administer them compliantly"
                    externalLink="learning-center/qsehra-learning-center/"
                />
            </ResourceCenter>
            <div className="products-hra-wrapper">
                <HraContact />
            </div>
        </>
    )
}
