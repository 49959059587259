import { Helmet } from "react-helmet-async"

import ProductsHero from "../components/Products/ProductsHero"
import "../components/Products/Products.css"
import HraContact from "../components/Hra/HraContact"
import PriceComparison from "../components/Products/PriceComparison"
import ResourceCenter from "../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../components/Resource-Center/ResourceCenterVideo"

import ichraVsQsehra from "../assets/ichravsqsehra-poster.svg"
import qsehraRules from "../assets/QSEHRA-Learning-Center.png"
import guideQsehra from "../assets/guide-salusions.svg"

import { PRICING_DATA } from "../components/Products/data"
import { TABLE_DATA } from "../components/Products/data"

export default function Qsehra() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "Product",
		"name": "Best QSEHRA for Small Businesses | Simple & Affordable | Salusion",
		"description": "Salusion makes QSEHRA administration easy for small businesses—affordable, IRS-compliant, and hassle-free. Offer tax-free health benefits today!",
		"brand": {
			"@type": "Brand",
			"name": "Salusion"
		},
		"offers": {
			"@type": "Offer",
			"price": "14.00",
			"priceCurrency": "USD",
			"availability": "https://schema.org/InStock",
			"priceSpecification": {
				"@type": "UnitPriceSpecification",
				"price": "14.00",
				"priceCurrency": "USD",
				"billingDuration": 1,
				"billingIncrement": 1,
				"billingPeriod": "http://purl.org/goodrelations/v1#Month",
				"unitText": "participant/month",
			},
			"eligibleQuantity": {
				"@type": "QuantitativeValue",
				"value": 1,
				"unitText": "participant"
			},
			"shippingDetails": {
				"@type": "OfferShippingDetails",
				"shippingRate": {
					"@type": "MonetaryAmount",
					"value": "0.00",
					"currency": "USD"
				},
				"shippingDestination": {
					"@type": "DefinedRegion",
					"name": "United States"
				},
				"deliveryTime": {
					"@type": "QuantitativeValue",
					"value": "0",
					"unitCode": "DAY"
				}
			}
		},
		"image": "https://salusion.com/assets/images/homepage/reimbursement.svg",
		"url": "https://www.salusion.com/product/qsehra",
		"datePublished": "2024-10-05T14:30:00+00:00",
		"dateModified": "2024-10-05T14:30:00+00:00",

	}
	return (
		<>
			<Helmet>
				<title>Best QSEHRA for Small Businesses | Simple & Affordable | Salusion</title>
				<meta name="title" content="Best QSEHRA for Small Businesses | Simple & Affordable | Salusion" />
				<meta name="description" content="Salusion makes QSEHRA administration easy for small businesses—affordable, IRS-compliant, and hassle-free. Offer tax-free health benefits today!" />
				<meta name="keywords" content="small business QSEHRA, tax-free benefit, simple HRA administration" />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>
			<ProductsHero
				title="Simple, Affordable QSEHRAs"
				subtitle="Salusion’s QSEHRA software simplifies administration and helps employers manage tax-free health reimbursements.  Start your QSEHRA today with the industry’s most cost-effective solution."
				poster={guideQsehra}
				video="https://www.youtube.com/embed/i2ERXJJkxkw?si=zO81hNyHZGOkrluN"
				autoPlay={false}
			/>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={ichraVsQsehra}
					title="Which is right for your small business?"
					externalLink="learning-center/ichra-vs-qsehra-which-hra-is-right-for-your-business/"
				/>

				<ResourceCenterVideo
					resourceImg={qsehraRules}
					title="Learn what QSEHRAs do, how they work, and how to administer them compliantly"
					externalLink="learning-center/qsehra-learning-center/"
				/>
			</ResourceCenter>
			<PriceComparison title="QSEHRA Price Comparison" dataPricing={PRICING_DATA} dataCheckmark={TABLE_DATA} />
			<div className="products-hra-wrapper">
				<HraContact />
			</div>
		</>
	)
}
