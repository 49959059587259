import { Helmet } from "react-helmet-async"

import ProductsHero from "../components/Products/ProductsHero"
import "../components/Products/Products.css"
import HraContact from "../components/Hra/HraContact"
import PriceComparison from "../components/Products/PriceComparison"
import ResourceCenter from "../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../components/Resource-Center/ResourceCenterVideo"

import ichraVsQsehra from "../assets/ichravsqsehra-poster.svg"
import qsehraRules from "../assets/QSEHRA-Learning-Center.png"
import guideQsehra from "../assets/guide-salusions.svg"

import { PRICING_DATA } from "../components/Products/data"
import { TABLE_DATA } from "../components/Products/data"

export default function QsehraVideoGuide() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "VideoObject",
		"name": "QSEHRA Video | Step-by-Step Administration & Setup | Salusion",
		"description": "Watch Salusion’s video guide to learn everything you need to know to start and administer a QSEHRA today. From employee eligibility to reimbursement methods, get step-by-step instructions to set up your plan quickly and compliantly.",
		"thumbnailUrl": "https://salusion.com/assets/icons/logos/favicons/mini-logo-152x152.png",
		"uploadDate": "2024-10-05T14:30:00+00:00",
		"duration": "PT9M44S",
		"contentUrl": "https://www.youtube.com/embed/i2ERXJJkxkw?si=zO81hNyHZGOkrluN"
	}

	return (
		<>
			<Helmet>
				<title>QSEHRA Video | Step-by-Step Administration & Setup | Salusion</title>
				<meta name="title" content="QSEHRA Video | Step-by-Step Administration & Setup | Salusion" />
				<meta name="description" content="Watch Salusion’s video guide to learn everything you need to know to start and administer a QSEHRA today. From employee eligibility to reimbursement methods, get step-by-step instructions to set up your plan quickly and compliantly." />
				<meta name="keywords" content="QSEHRA video setup, administration, step-by-step instructions" />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>
			<ProductsHero
				title="QSEHRA Video Guide"
				subtitle="Click on the image below to watch Salusion’s video guide. Learn everything you need to know to start and administer a QSEHRA today. From employee eligibility to reimbursement methods, get step-by-step instructions to set up your plan quickly and compliantly."
				poster={guideQsehra}
				video="https://www.youtube.com/embed/i2ERXJJkxkw?si=zO81hNyHZGOkrluN"
				autoPlay={false}
			/>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={ichraVsQsehra}
					title="Which is right for your small business?"
					externalLink="learning-center/ichra-vs-qsehra-which-hra-is-right-for-your-business/"
				/>

				<ResourceCenterVideo
					resourceImg={qsehraRules}
					title="Learn what QSEHRAs do, how they work, and how to administer them compliantly"
					externalLink="learning-center/qsehra-learning-center/"
				/>
			</ResourceCenter>
			<PriceComparison title="QSEHRA Price Comparison" dataPricing={PRICING_DATA} dataCheckmark={TABLE_DATA} />
			<div className="products-hra-wrapper">
				<HraContact />
			</div>
		</>
	)
}
