import { Helmet } from "react-helmet-async"
import "../components/Homepage/Homepage.css"

import Hero from "../components/Referral/Hero"
import Instructions from "../components/Referral/Instructions"

export default function Referral() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "WebPage",
		"name": "Salusion Referral Program",
		"description": "Join Salusion's Referral Program and help companies enhance their employee benefits with our cost-effective HRAs. Refer a company and get rewarded for supporting better benefits.",
		"mainEntity": {
			"@type": "Organization",
			"name": "Salusion, Inc.",
			"url": "https://alusion.com",
			"logo": "https://salusion.com/assets/icons/logos/favicons/mini-logo-152x152.png",
			"offers": {
				"@type": "Offer",
				"name": "Referral Reward",
				"description": "Refer a company and earn up to $42 per participant.",
				"price": "42.00",
				"priceSpecification": {
					"@type": "PriceSpecification",
					"maxPrice": "42.00"
				},
				"priceCurrency": "USD",
				"shippingDetails": {
					"@type": "OfferShippingDetails",
					"shippingRate": {
						"@type": "MonetaryAmount",
						"value": "0.00",
						"currency": "USD"
					},
					"shippingDestination": {
						"@type": "DefinedRegion",
						"name": "United States"
					},
					"deliveryTime": {
						"@type": "QuantitativeValue",
						"value": "0",
						"unitCode": "DAY"
					}
				}
			}
		}
	}
	return (
		<>
			<Helmet>
				<title>Health Reimbursement Arrangement (HRA) Referrals - Support Better Benefits and Get Rewarded | Salusion</title>
				<meta name="title" content="Support Better Benefits and Get Rewarded | Salusion Referral Program" />
				<meta name="description" content="Join Salusion's Referral Program and help companies enhance their employee benefits with our cost-effective HRAs. Refer a company and get rewarded for supporting better benefits." />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>

			</Helmet>

			<Hero />
			<Instructions />
		</>
	)
}
