import { Helmet } from "react-helmet-async"

import "../components/Homepage/Homepage.css"

import Hero from "../components/Homepage/Hero"
import CompanyPackages from "../components/Homepage/company-packages/CompanyPackages"
import SalusionSimplifies from "../components/Homepage/salusion-simplifies/SalusionSimplifies"
import Testimonials from "../components/Homepage/testimonials/Testimonials"
import Benefits from "../components/Homepage/benefits/Benefits"
import Hra from "../components/Hra/HraContact"

export default function Homepage() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "Product",
		"name": "Simple & Affordable HRAs | Salusion",
		"description": "Salusion simplifies ICHRA, QSEHRA, GCHRA, and EBHRA administration for small businesses. Offer tax-free health benefits with an affordable, IRS-compliant solution.",
		"brand": {
			"@type": "Brand",
			"name": "Salusion"
		},
		"offers": [
			{
				"@type": "Offer",
				"name": "ICHRA",
				"description": "Individual Coverage Health Reimbursement Arrangement (ICHRA)",
				"price": "14.00",
				"priceCurrency": "USD",
				"availability": "https://schema.org/InStock",
				"priceSpecification": {
					"@type": "UnitPriceSpecification",
					"price": "14.00",
					"priceCurrency": "USD",
					"billingDuration": 1,
					"billingIncrement": 1,
					"billingPeriod": "http://purl.org/goodrelations/v1#Month",
					"unitText": "participant/month",
				},
				"eligibleQuantity": {
					"@type": "QuantitativeValue",
					"value": 1,
					"unitText": "participant"
				},
				"shippingDetails": {
					"@type": "OfferShippingDetails",
					"shippingRate": {
						"@type": "MonetaryAmount",
						"value": "0.00",
						"currency": "USD"
					},
					"shippingDestination": {
						"@type": "DefinedRegion",
						"name": "United States"
					},
					"deliveryTime": {
						"@type": "QuantitativeValue",
						"value": "0",
						"unitCode": "DAY"
					}
				}
				},
			{
				"@type": "Offer",
				"name": "QSEHRA",
				"description": "Qualified Small Employer Health Reimbursement Arrangement (QSEHRA)",
				"price": "14.00",
				"priceCurrency": "USD",
				"availability": "https://schema.org/InStock",
				"priceSpecification": {
					"@type": "UnitPriceSpecification",
					"price": "14.00",
					"priceCurrency": "USD",
					"billingDuration": 1,
					"billingIncrement": 1,
					"billingPeriod": "http://purl.org/goodrelations/v1#Month",
					"unitText": "participant/month",
				},
				"eligibleQuantity": {
					"@type": "QuantitativeValue",
					"value": 1,
					"unitText": "participant"
				},
			},
			{
				"@type": "Offer",
				"name": "GCHRA",
				"description": "Group Coverage Health Reimbursement Arrangement (ICHRA)",
				"price": "5.00",
				"priceCurrency": "USD",
				"availability": "https://schema.org/InStock",
				"priceSpecification": {
					"@type": "UnitPriceSpecification",
					"price": "5.00",
					"priceCurrency": "USD",
					"billingDuration": 1,
					"billingIncrement": 1,
					"billingPeriod": "http://purl.org/goodrelations/v1#Month",
					"unitText": "participant/month",
				},
				"eligibleQuantity": {
					"@type": "QuantitativeValue",
					"value": 1,
					"unitText": "participant"
				},
			},
			{
				"@type": "Offer",
				"name": "EBHRA",
				"description": "Excepted Benefit Health Reimbursement Arrangement (ICHRA)",
				"price": "5.00",
				"priceCurrency": "USD",
				"availability": "https://schema.org/InStock",
				"priceSpecification": {
					"@type": "UnitPriceSpecification",
					"price": "5.00",
					"priceCurrency": "USD",
					"billingDuration": 1,
					"billingIncrement": 1,
					"billingPeriod": "http://purl.org/goodrelations/v1#Month",
					"unitText": "participant/month",
				},
				"eligibleQuantity": {
					"@type": "QuantitativeValue",
					"value": 1,
					"unitText": "participant"
				},
			},
		],
		"image": "https://salusion.com/assets/images/homepage/reimbursement.svg",
		"url": "https://www.salusion.com/product/gchra",
		"datePublished": "2024-10-05T14:30:00+00:00",
		"dateModified": "2024-10-05T14:30:00+00:00",

	}
	return (
		<>
			<Helmet>
				<title>Simple & Affordable HRAs | Salusion</title>
				<meta name="title" content="Simple & Affordable HRAs | Salusion" />
				<meta name="description" content="Salusion simplifies ICHRA, QSEHRA, GCHRA, and EBHRA administration for small businesses. Offer tax-free health benefits with an affordable, IRS-compliant solution." />
				<meta name="keywords" content="Small Business HRA administration software, ICHRA, QSEHRA, Affordable HRA, tax-free benefit, GCHRA, EBHRA" />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>
			<Hero />
			<CompanyPackages />
			<SalusionSimplifies />
			<Benefits />
			<Testimonials />
			<Hra />
		</>
	)
}
