import { NavLink } from "react-router-dom"
import { useState, useEffect } from "react"

import { toSalusionPrivate } from "../../util"
import "./MainNavigation.css"

import arrowDown from "../../assets/arrow-down.svg"
import logo from "../../assets/logo.svg"
import qsehra from "../../assets/qsehra.png"
import ichra from "../../assets/ichra.png"
import gchra from "../../assets/gchra.png"
import ebhra from "../../assets/ebhra.png"
import DropdownMenu from "./Dropdown"

export default function MainNavigation() {
	const [isOpen, setIsOpen] = useState(false)
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)

	function handleMenu() {
		setIsOpen((prev) => !prev)
	}

	function toLearningCenter() {
		window.open("https://salusion.com/learning-center", "_blank")
		handleMenu()
	}

	function toggleDropdown(event) {
		event.preventDefault()
		setIsDropdownOpen((prev) => !prev)
	}

	useEffect(() => {
		const mediaQuery = window.matchMedia("(max-width: 1024px)")

		if (mediaQuery.matches && isOpen) {
			document.querySelector("body").classList.add("active")
		} else {
			document.querySelector("body").classList.remove("active")
		}

		return () => {
			document.querySelector("body").classList.remove("active")
		}
	}, [isOpen])

	return (
		<header className="header-wrapper">
			<div className="container">
				<div className="main-nav">
					<div className="main-nav__logo">
						<NavLink to="/">
							<img src={logo} alt="salusion logo" />
						</NavLink>
					</div>

					<div className={`main-nav__items ${isOpen ? "active" : ""}`}>
						<div className="has-dropdown">
							<NavLink onClick={handleMenu}>Products</NavLink>

							<button className="dropdown-toggle-custom" onClick={toggleDropdown}>
								<img src={arrowDown} alt="choose hra" style={{ marginBottom: "1px" }} />
							</button>

							<div className={`dropdown-menu-all ${isDropdownOpen ? "show" : ""}`}>
								<DropdownMenu clickMenu={handleMenu} text="Individual Coverage HRA" link="/product/ichra" img={ichra} />

								<DropdownMenu
									clickMenu={handleMenu}
									text="Qualified Small Employer HRA"
									link="/product/qsehra"
									img={qsehra}
								/>

								<DropdownMenu clickMenu={handleMenu} text="Group Coverage HRA" link="/product/gchra" img={gchra} />
								<DropdownMenu clickMenu={handleMenu} text="Excepted Benefit HRA" link="/product/ebhra" img={ebhra} />
							</div>
						</div>

						<NavLink to="/pricing" onClick={handleMenu}>
							Pricing
						</NavLink>

						<NavLink to="/referral-program" onClick={handleMenu}>
							Referrals
						</NavLink>						

						<NavLink onClick={toLearningCenter}>
							Learning Center
						</NavLink>

						<NavLink to="/contact-us" onClick={handleMenu}>
							Contact Us
						</NavLink>

						<a href="_" onClick={(event) => toSalusionPrivate(event, "/", false)}>
							Sign In
						</a>
					</div>

					<div className={`main-nav__hamburger ${isOpen ? "active" : ""}`} onClick={handleMenu}>
						<span className="line-1"></span>
						<span className="line-2"></span>
					</div>
				</div>
			</div>
		</header>
	)
}
