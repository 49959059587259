import { Helmet } from "react-helmet-async"
import Contact from "../components/Contact/Contact"

export default function ContactUs() {
	return (
		<>
			<Helmet>
				<title>Contact Us About a Health Reimbursement Arrangement (HRA) | Salusion</title>
			</Helmet>
			<Contact />
		</>
	)
}
