import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import TestimonialCard from "./TestimonialCard"

import "swiper/css"
import "swiper/css/navigation"

export default function EmployersSlider() {
	return (
		<>
			<Swiper
				modules={[Navigation]}
				spaceBetween={32}
				slidesPerView={5.5}
				// navigation={true}
				loop
				navigation={{
					nextEl: ".image-swiper-button-next",
					prevEl: ".image-swiper-button-prev"
				}}
				breakpoints={{
					0: {
						slidesPerView: 1
					},
					767: {
						slidesPerView: 2.5
					},
					1200: {
						slidesPerView: 4.5
					}
				}}
			>

				<SwiperSlide>
					<TestimonialCard
						name="Varda L."
						text="Super easy mid year migration to Salusion after nightmare experience with TakeCommand. Super impressed with Giles Nugent who immediately understood my concerns based on experience with prior company. Very excited to be shown that you can always reach support via chat box or zoom appointment if needs arise since the other company made you only communicate via email which is extraordinarily inefficient and error prone. Assuming my experience going forward is as good as today, I will be recommending Salusion to all business owners that I know and recommending them on next door app, Google etc. Yay for a company that is well organized, professional and knowledgeable. So excited that I won’t have to worry anymore about this important issue!"
					/>
				</SwiperSlide>

				<SwiperSlide>
					<TestimonialCard
						name="Doug K."
						text="We've been with Salusion for four months now. It was incredibly easy to sign up and get our plan in place. Their dashboard is easy to use. Onboarding our employees was simple and quick. Their pricing is quite reasonable. We started with a QSEHRA and recently switched to ICHRA. I did a 10-minute Zoom call, and they made the changes for us. Of all our HR-related services, Salusion is by far the easiest to use and manage."
					/>
				</SwiperSlide>

				<SwiperSlide>
					<TestimonialCard
						name="Tracie S."
						text="Great customer service - Giles was most helpful in assisting us in offering benefits for our employees and in answering questions quickly in a convenient manner. Also a great online portal experience for both employees and employer - simple, straightforward, and easy to use. I highly recommend Salusion!"
					/>
				</SwiperSlide>

				<SwiperSlide>
					<TestimonialCard
						name="David N."
						text="Our experience with Salusion has been top notch.  We are a small non-profit and their platform works great for us.  Support has been a dream.  The ability to schedule specific times for a support conference works perfectly.  More importantly, I have had my questions answered and support done in a very professional and time efficient manner."
					/>
				</SwiperSlide>

				<SwiperSlide>
					<TestimonialCard
						name="Robert F."
						text="We switched our HRA plan from another company to Salusion about a year and a half ago.  Salusion did a great job of educating us and providing resources during the transition.  Since then they've been a pleasure to work with.  They're responsive to our questions and keep our employees happier than they were with our previous provider.  Submitting reimbursement requests is easy and requests are reliably paid."
					/>
				</SwiperSlide>

				<SwiperSlide>
					<TestimonialCard
						name="Doug K."
						text="Giles was extremely helpful and knowledgeable about Salusion and helping to get us set up. We had a little trouble with the website and Giles jumped in to get us going. i am very impressed with how you use technology to get things set up and how quickly I got help along the way. Giles has a strong sense of treating customers with respect and has a mannerism that makes things easy to understand. We look forward to a long, rewarding relationship withi Salusion. Just an FYI, we had conversations with two other HRA providers but literally chose Salusion because of the experience we had with Giles."
					/>
				</SwiperSlide>

				<SwiperSlide>
					<TestimonialCard
						name="Becky S."
						text="As a new customer, I had the pleasure of getting outstanding help from Giles.  He did a zoom call with me, help with every step and made it an easy transition.   Polite and caring to make certain we had what was needed."
					/>
				</SwiperSlide>

				<SwiperSlide>
					<TestimonialCard
						name="Malia R."
						text="I had the pleasure of working with Salusion as an insurance broker, as well a business client, and I couldn't be more impressed with their service. Their platform is incredibly user-friendly, making it easy for both employers and employees to navigate, which significantly streamlines the benefits management process. I've personally worked with Sean & Giles at the Salusion's team, who consistently went above and beyond to ensure our needs were met, providing personalized support that made a real difference. Their competitive pricing is another standout feature, offering exceptional value without compromising on quality. I highly recommend Salusion to any business looking to enhance their benefits administration, particularly those who value a seamless experience and top-notch customer service. Partnering with Salusion has been a game-changer for us, and I'm confident it would be for others too."
					/>
				</SwiperSlide>

				<SwiperSlide>
					<TestimonialCard
						name="Dr. Megha S."
						text="I had the good fortune of being able to use Salusion and its products through my work place. Its products were very very easy to use. An issues or questions I had were resolved immediately with staff that were extremely knowledgable, helpful, friendly and through. I highly recommend Salusion. I specifically appreciated working with Giles Nugent. He is hands down the absoult best at his job! He immediate and professional attention, as well as kindness and pacience to make sure all issues are solved is above and beyond. He specifically made my experience A+. If I was to open my own business I would use Salusion. It is what any and every employee would want to use!"
					/>
				</SwiperSlide>

				<SwiperSlide>
					<TestimonialCard
						name="Alicia L."
						text="Our organization has had an outstanding experience with the Salusion application. The ease of use and the short turnaround time on reimbursements have been greatly appreciated. Our employees particularly appreciate how intuitive the interface is, making it simple to navigate and submit reimbursement requests. We highly recommend Salusion to any organization looking for a seamless and effective reimbursement solution."
					/>
				</SwiperSlide>

				<SwiperSlide>
					<TestimonialCard
						name="Kyle C."
						text="It took 10 minutes to set up a QSEHRA for my small business. I really like that they manage the reimbursements for me."
					/>
				</SwiperSlide>

				<SwiperSlide>
					<TestimonialCard
						name="James H."
						text="Exceptional service and support! Our employees appreciate the flexibility and coverage options."
					/>
				</SwiperSlide>
			</Swiper>
		</>
	)
}
