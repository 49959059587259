import { Helmet } from "react-helmet-async"

import "../components/Products/Products.css"
import ProductsHero from "../components/Products/ProductsHero"
import HraContact from "../components/Hra/HraContact"
import PriceComparison from "../components/Products/PriceComparison"
import ResourceCenterVideo from "../components/Resource-Center/ResourceCenterVideo"
import ResourceCenter from "../components/Resource-Center/ResourceCenter"

import ichraVsQsehra from "../assets/ichravsqsehra-poster.svg"
import ichraRules from "../assets/ICHRA-Learning-Center.png"
import employees from "../assets/50-employees.svg"
import ichraCalculator from "../assets/ichra-calculator.svg"
// import reimbursement from "../assets/reimbursement-options.svg"
import guideIchra from "../assets/guide-to-ichra.svg"

import { PRICING_DATA_ICHRA } from "../components/Products/data"
import { TABLE_DATA_ICHRA } from "../components/Products/data"

export default function IchraVideoGuide() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "VideoObject",
		"name": "QSEHRA Video | Step-by-Step Administration & Setup | Salusion",
		"description": "Watch Salusion’s video guide to learn everything you need to know to create and administer an ICHRA today. Explore employee eligibility, classes, and tax reporting to set up your ICHRA quickly and compliantly.",
		"thumbnailUrl": "https://salusion.com/assets/icons/logos/favicons/mini-logo-152x152.png",
		"uploadDate": "2024-10-05T14:30:00+00:00",
		"duration": "PT12M8S",
		"contentUrl": "https://www.youtube.com/embed/QI1M_6qYVao?si=uHs0hXI4W37s__Ff"
	}
	return (
		<>
			<Helmet>
				<title>QSEHRA Video | Step-by-Step Administration & Setup | Salusion</title>
				<meta name="title" content="QSEHRA Video | Step-by-Step Administration & Setup | Salusion" />
				<meta name="description" content="Watch Salusion’s video guide to learn everything you need to know to create and administer an ICHRA today. Explore employee eligibility, classes, and tax reporting to set up your ICHRA quickly and compliantly." />
				<meta name="keywords" content="ICHRA video setup, administration, step-by-step instructions" />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>
			<ProductsHero
				title="ICHRA Video Guide"
				subtitle="Click on the image below to watch Salusion’s video guide.  Learn everything you need to know to create and administer an ICHRA today. Explore employee eligibility, classes, and tax reporting to set up your ICHRA quickly and compliantly."
				video="https://www.youtube.com/embed/QI1M_6qYVao?si=uHs0hXI4W37s__Ff"
				poster={guideIchra}
				autoPlay={false}
			/>
			<ResourceCenter>
			<ResourceCenterVideo
					resourceImg={ichraVsQsehra}
					title="Which is right for your small business?"
					externalLink="learning-center/ichra-vs-qsehra-which-hra-is-right-for-your-business/"
				/>
				<ResourceCenterVideo
					resourceImg={ichraRules}
					title="Discover what ICHRAs do, how they work, and how to administer them compliantly."
					externalLink="learning-center/ichra-learning-center/"
				/>
				<ResourceCenterVideo
					resourceImg={employees}
					title="ICHRA topics for large employers"
					pageLink="/product/ichra/large-business"
				/>
				<ResourceCenterVideo
					resourceImg={ichraCalculator}
					title="Discover how much you can save with an ACA-compliant ICHRA"
					externalLink="product/ichra/aca-compliant-calculator"
				/>
				{/* <ResourceCenterVideo
					resourceImg={findBroker}
					title="Find a broker in your state that can help your employee find insurance"
					pageLink="/product/ichra/find-brokers"
				/> */}
				{/* <ResourceCenterVideo
					resourceImg={reimbursement}
					title="Discussion of the pros and cons of each reimbursement option"
					pageLink="/product/ichra/reimbursement-options"
				/> */}
			</ResourceCenter>
			<PriceComparison
				dataPricing={PRICING_DATA_ICHRA}
				dataCheckmark={TABLE_DATA_ICHRA}
				title="ICHRA Price Comparison"
			/>
			<HraContact />
		</>
	)
}
