export default function toSalusionPrivate(event, path = "", newWindow = true) {
	if (event) event.preventDefault()

	let hostname = window.location.hostname
	hostname = hostname.replace(/^www\./, "")

	path = path.replace(/^\/+/, "")

	let target = "_self"
	if (newWindow) target = "_blank"
	if (path.includes("learning-center")) {
		window.open(`https://salusion.com/${path}`, target)
	} else if (hostname === "localhost") {
		window.open(`http://localhost:8080/${path}`, target)
	} else {
		window.open(`https://my.${hostname}/${path}`, target)
	}
}
