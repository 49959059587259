import "../components/Rules/Rules.css"
import HraContact from "../components/Hra/HraContact"
import PriceComparison from "../components/Products/PriceComparison"
import ResourceCenter from "../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../components/Resource-Center/ResourceCenterVideo"

import ichraVsQsehra from "../assets/ichravsqsehra-poster.svg"
import qsehraRules from "../assets/QSEHRA-Learning-Center.png"
import guideQsehra from "../assets/guide-salusions.svg"

import { PRICING_DATA } from "../components/Products/data"
import { TABLE_DATA } from "../components/Products/data"
import { Helmet } from "react-helmet-async"

export default function QsehraConsultation() {
    const schema = {
        "@context": "https://schema.org",
        "@type": "Event",
        "name": "Book a QSEHRA Consultation",
        "description": "Schedule a consultation with a QSEHRA expert to learn how to set up and administer a QSEHRA for your small business.",
        "location": {
            "@type": "Place",
            "name": "Virtual Meeting Room",
        },
        "offers": {
            "@type": "Offer",
            "price": "14.00 / participant / month",
            "priceCurrency": "USD",
            "priceSpecification": {
                "@type": "UnitPriceSpecification",
                "price": "14.00",
                "priceCurrency": "USD",
                "billingDuration": 1,
                "billingIncrement": 1,
                "billingPeriod": "http://purl.org/goodrelations/v1#Month",
                "unitText": "participant/month",
            },
            "eligibleQuantity": {
                "@type": "QuantitativeValue",
                "value": 1,
                "unitText": "participant"
            },
            "shippingDetails": {
                "@type": "OfferShippingDetails",
                "shippingRate": {
                    "@type": "MonetaryAmount",
                    "value": "0.00",
                    "currency": "USD"
                },
                "shippingDestination": {
                    "@type": "DefinedRegion",
                    "name": "United States"
                },
                "deliveryTime": {
                    "@type": "QuantitativeValue",
                    "value": "0",
                    "unitCode": "DAY"
                }
            }
        },
        "performer": {
            "@type": "Person",
            "name": "Salusion Expert",
            "url": "https://salusion.com"
        },
        "registrationURL": "https://calendly.com/salusion-inquiry/salusion-support?month=2024-10"
    }

    return (
        <>
            <Helmet>
                <title>Qualified Small Employer HRA (QSEHRA) Consultation | Salusion</title>
                <meta name="title" content="Book a QSEHRA Consultation | Salusion" />
                <meta name="description" content="Schedule a consultation with a QSEHRA expert to learn how to set up and administer a QSEHRA for your small business." />
                <script type="application/ld+json">{JSON.stringify(schema)}</script>
            </Helmet>

            <section id="gsehra_rules" className="first-section-full-background first-padding rules-all">
                <div className="container">
                    <h1>Book a QSEHRA Consultation</h1>
                    <h3 style={{ color: "#ffffff" }}>Schedule a consultation with a QSEHRA expert to learn how to set up and administer a QSEHRA for your small business.</h3>
                    <br />
                    <div className="products-hra-wrapper">
                        <HraContact />
                    </div>
                </div>
            </section>

            <ResourceCenter>
                <ResourceCenterVideo
                    resourceImg={guideQsehra}
                    title="Provide tax-advantaged benefits simply and affordably with Salusion"
                    pageLink="/product/qsehra/video-guide"
                />
				<ResourceCenterVideo
					resourceImg={ichraVsQsehra}
					title="Which is right for your small business?"
					externalLink="learning-center/ichra-vs-qsehra-which-hra-is-right-for-your-business/"
				/>

                <ResourceCenterVideo
                    resourceImg={qsehraRules}
                    title="Learn what QSEHRAs do, how they work, and how to administer them compliantly"
                    externalLink="learning-center/qsehra-learning-center/"
                />
            </ResourceCenter>
            <PriceComparison title="QSEHRA Price Comparison" dataPricing={PRICING_DATA} dataCheckmark={TABLE_DATA} />
        </>
    )
}
