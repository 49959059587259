import { Helmet } from "react-helmet-async"

import "../components/Privacy/Privacy.css"
import { Link } from "react-router-dom"

export default function PrivacyPolicy() {
	return (
		<>
			<Helmet>
				<title>Salusion Privacy Policy</title>
			</Helmet>
			<section className="first-section first-padding privacy-section">
				<div className="container">
					<div className="white-wrapper-all">
						<div className="privacy-wrapper">
							<h1>Privacy Policy</h1>
							<h3>Version 2.3 | Last Revised: July 20, 2021</h3>
							<div className="privacy-wrapper-parent">
								<p>
									At Salusion, Inc. ("Salusion", "we", or "us"), we take our responsibility to safeguard your personal
									information extremely seriously. This Privacy Policy explains how we acquire, use, and transmit your
									Personal Information (as defined below). We deliver our product through our
									<Link to="/">Website</Link>, our mobile application ("Application"), and our widgets (collectively,
									the "Site") and online services, including the establishment of a savings account to hold funds, the
									option to receive a debit card to spend funds on qualified expenses, and the ability to establish an
									investment account (all collectively the ("Services")). We may update this Privacy Policy from time to
									time. We will notify you by posting a notification on the Site and through the Services and/or by
									sending you an email about the update(s).
								</p>
								<div className="privacy-inner-child">
									<h6>I. Personal Information that Salusion Collects</h6>
									<p>
										Salusion collects information about you as a user to provide you with the Services. We collect
										information about you in a range of forms, including Personal Information. "Personal Information"
										means data that allows someone to identify or contact you, including, for example, your name,
										address, telephone number, email address, as well as any other non-public information about you that
										is associated with or linked to any of the foregoing data. For purposes of this Privacy Policy,
										Personal Information also includes Non-Public Information, as that term is defined in the
										Gramm-Leach-Bliley Act of 1999 ("NPI") and Protected Health Information, as that term is defined in
										the Health Insurance Portability and Accountability Act of 1996 ("PHI"). "Anonymous Data" means data
										that is not associated with or linked to your Personal Information Anonymous Data does not, by
										itself, permit the identification of individual persons. We collect Personal Information and
										Anonymous Data, as described below.
									</p>
								</div>
								<div className="privacy-inner-child privacy-inner-child-space">
									<h6>A. Information from the User</h6>
									<p>
										When you create an account to log in to our network ("Account"), you may be prompted to provide some
										or all of the following information in connection with our provision of the Services:
									</p>
									<ul>
										<li>Name</li>
										<li>Physical Address</li>
										<li>Mailing Address</li>
										<li>Social Security Number</li>
										<li>Employer Identification Number (EIN)</li>
										<li>Date of Birth</li>
										<li>Email Address</li>
										<li>Phone Number</li>
										<li>Gender</li>
										<li>Health plan information</li>
										<li>Beneficiary Information</li>
										<li>Dependent information</li>
										<li>Medical expense information (including receipts), which may contain PHI</li>
										<li>Existing Health Savings Account information (for rollover purposes)</li>
										<li>HSA-related investment account information</li>
										<li>Personal bank account information</li>
										<li>
											If you use our Services on your mobile device, including through our Application, we may collect
											your phone number and the unique device ID number.
										</li>
										<li>
											If you tell us where you are (e.g., by allowing your mobile device to send us your location), we
											may store and use that information to provide you with location-based information and advertising.
											If you want to deactivate this feature, you can deactivate GPS on your mobile device.
										</li>
										<li>
											Our Site may let you store preferences like how your content is displayed, your location, safe
											search settings, and favorite widgets. We may associate these choices with your ID, browser or the
											mobile device, and you can edit these preferences at any time.
										</li>
										<li>
											When connecting to our Services via a service provider that uniquely identifies your mobile
											device, we may receive this identification and use it to offer extended services and/or
											functionality.
										</li>
										<li>
											Certain Services, such as two-factor authentication and text messaging, may require our collection
											of your phone number. We may associate that phone number to your mobile device identification
											information.
										</li>
										<li>
											We retain information on your behalf, such as files and messages that you store using your
											Account.
										</li>
										<li>
											If you provide us with feedback or contact us via email, we will collect your name and email
											address, as well as any other content included in the email, in order to send you a reply.
										</li>
										<li>
											If you post messages on the message boards of our Site, the information contained in your posting
											will be stored on our servers and other users will be able to see it.
										</li>
										<li>If you participate in one of our surveys, we may collect additional profile information.</li>
										<li>
											We also collect other types of Personal Information that you provide to us voluntarily, such as
											your operating system and version and other requested information if you contact us via email
											regarding support for the Services.
										</li>
										<li>
											We may also collect Personal Information at other points on our Site where it states that Personal
											Information is being collected.
										</li>
									</ul>
								</div>
								<div className="privacy-inner-child privacy-inner-child-space">
									<h6>B. Information Collected Automatically</h6>
									<ul>
										<li>
											Information Collected by Our Servers. To make our Site and Services more useful to you, our
											servers (which may be hosted by a third party service provider) collect information from you,
											including your browser type, operating system, Internet Protocol ("IP") address (a number that is
											automatically assigned to your computer when you use the Internet, which may vary from session to
											session), domain name, and/or a date/time stamp for your visit.
										</li>
										<li>
											Log Files. As is typical of most websites, we gather certain information automatically and store
											it in log files. This information includes IP addresses, browser type, Internet service provider
											("ISP") , referring/exit pages, operating system, date/time stamp, and clickstream data. We use
											this information to analyze trends, administer the Site, track users' movements around the Site,
											gather demographic information about our user base as a whole, and better tailor our Services to
											our users' needs. For example, some of the information may be collected so that when you visit the
											Site or the Services again, it will recognize you and the information could then be used to serve
											advertisements and other information appropriate to your interests. Except as noted in this
											Privacy Policy, we do not link this automatically-collected data to Personal Information.
										</li>
										<li>
											Cookies. We sometimes use cookies to collect information. "Cookies" are small pieces of
											information that a website sends to your computer's hard drive while you are viewing the website.
											We may use both session Cookies (which expire once you close your web browser) and persistent
											Cookies (which stay on your computer until you delete them) to provide you with a more personal
											and interactive experience on our Site. This type of information is collected to make the Site
											more useful to you and to tailor the experience with us to meet your special interests and needs.
										</li>
										<li>
											Pixel Tags. In addition, we use "Pixel Tags" (also referred to as clear gifs, Web beacons, or Web
											bugs). Pixel Tags are tiny graphic images with a unique identifier, similar in function to
											Cookies, that are used to track online movements of Web users. In contrast to Cookies, which are
											stored on a user's computer hard drive, Pixel Tags are embedded invisibly in Web pages. Pixel Tags
											also allow us to send email messages in a format users can read, and they tell us whether emails
											have been opened to ensure that we are sending only messages that are of interest to our users. We
											may use this information to reduce or eliminate messages sent to a user. We do not tie the
											information gathered by Pixel Tags to our users' Personal Information.
										</li>
										<li>
											Collection of Data by Advertisers. We may also use third parties to serve ads on the Site. Certain
											third parties may automatically collect information about your visits to this and other websites,
											your IP address, your ISP, the browser you use to visit our Site (but not your name, address,
											email address or telephone number). They do this by using Cookies, Pixel Tags, or other
											technologies. Information collected may be used, among other things, to deliver advertising
											targeted to your interests and to better understand the usage and visitation of our Site and the
											other sites tracked by these third parties. This policy does not apply to, and we are not
											responsible for, Cookies or Pixel Tags in third party ads, and we encourage you to check the
											privacy policies of advertisers and/or ad services to learn about their use of Cookies and other
											technologies. You may opt out of receiving interest-based advertising from some of our partners by
											emailing
											<a href="mailto:support@salusion.com" target="_top">
												support@salusion.com
											</a>
											. If you would like more information about this practice and to know your choices about not having
											this information used by these companies, click here:
											<a href="https://optout.aboutads.info" target="_blank">
												https://optout.aboutads.info
											</a>{" "}
											or
											<a href="https://thenai.org" target="_blank">
												https://thenai.org
											</a>
											.
										</li>
										<li>
											How We Respond to Do Not Track Signals. We do not currently respond to "do not track" signals or
											other mechanisms that might enable Users to opt out of tracking on our Site.
										</li>
										<li>
											Mobile Services. We may also collect non-Personal Information from your mobile device. This
											information is generally used to help us deliver the most relevant information to you. Examples of
											information that may be collected and used include your geographic location, how you use the Site
											and Services, and information about the type of device you use. This information is sent to us as
											aggregated information and is not traceable to any individual and cannot be used to identify an
											individual.
										</li>
										<li>
											Browsing-Session Tracking. We may track your browsing activities on our Site and Application in
											other ways, including by recording, the actions you take, the time you spend, and the features
											that you utilize. This information may be used to create Anonymous Data to help us better
											understand customer preferences and needs. Except to the extent we utilize third-party services to
											assist in the collection of this data, we do not share this information with any other party.
										</li>
										<li>
											Analytics Services and Interest-Based Advertising. In addition to the tracking technologies we
											place, other companies may set their own cookies or similar tools when you visit our Site. This
											includes third party analytics services, including but not limited to Google Analytics ("Analytics
											Services"), that we engage to help analyze how users use the Site, as well as third parties that
											deliver content or offers. We may receive reports based on these parties' use of these tools on an
											individual or aggregate basis. We use the information we get from Analytics Services only to
											improve our Site and Services. The information generated by the Cookies or other technologies
											about your use of our Site and Services (the "Analytics Information") is transmitted to the
											Analytics Services. The Analytics Services use Analytics Information to compile reports on user
											activity. The Analytics Services may also transfer information to third parties where required to
											do so by law, or where such third parties process Analytics Information on their behalf. Each
											Analytics Services' ability to use and share Analytics Information is restricted by such Analytics
											Services' Terms of Use and Privacy Policy. By using our Site and Services, you consent to the
											processing of data about you by Analytics Services in the manner and for the purposes set out
											above. For a full list of Analytics Services, please contact us at
											<a href="mailto:support@salusion.com" target="_top">
												support@salusion.com
											</a>
											. We may also partner with ad companies to support our marketing efforts, including by serving you
											ads better tailored to your likely interests. If you don't want to take advantage of these
											services, you may be able to opt out by visiting
											<a href="https://optout.aboutads.info" target="_blank">
												https://optout.aboutads.info
											</a>{" "}
											or
											<a href="https://thenai.org" target="_blank">
												https://thenai.org
											</a>
											.
										</li>
									</ul>
								</div>
								<div className="privacy-inner-child privacy-inner-child-space">
									<h6>C. Information Collected from Third Party Companies</h6>
									<p>
										We may receive Personal Information and/or Anonymous Data about you from companies that help us
										verify your identity, comply with legal obligations, and provide our Services. We may also receive
										Personal Information and/or Anonymous Data about you from co-branded or private-labeled websites or
										companies that offer their products and/or services on our Site. Additionally, we may receive
										certain publicly available information about you from companies we partner with to provide us market
										research. These third party companies may supply us with Personal Information. We may add this
										information to the information we have already collected from you via our Site in order to improve
										the Services we provide.
									</p>
									<p>
										We may offer features and/or functionality that require you to enter other Personal Information. In
										these cases, we will use the information, including sharing the information with third parties as
										may be necessary, to provide the features and/or functionality described.
									</p>
								</div>
								<div className="privacy-inner-child privacy-inner-child-space">
									<h6>D. Information Collected from Employers, Payroll Companies, or Health Plans</h6>
									<p>
										If your Account was established by your employer or your health plan, they may have requested that
										we set up a communication channel whereby you can log into Salusion's portal (known as single sign
										on) or access your Account information through their site without entering your user name and
										password again. Your employer or health plan may have also contracted with another vendor that
										provides additional services, such as personal health care costs management or online benefits
										enrollment, that allows you to log into Salusion's portal or access your Account through that
										vendor's website, also without entering your Salusion username and password. When you use those
										websites to sign into Salusion's portal or access your Account, you are authorizing Salusion's
										system to pass on certain information, such as your account balances, to those systems.
									</p>
								</div>
								<div className="privacy-inner-child privacy-inner-child-space">
									<h6>E. Information Collected from Banks and Financial Institutions</h6>
									<p>We collect information from banks and/or financial institutions in several different cases.</p>
									<ul>
										<li>
											If you had previously established an account through another provider with a bank or financial
											institution, we may receive information pertaining to your account, deposit and withdrawal
											history, and financial transactions.
										</li>
										<li>
											In the establishment and ongoing maintenance of your Account with Salusion, we will receive
											information from our banking and financial institution partners related to your Account in order
											to provide the Services.
										</li>
										<li>
											You may receive separate terms and conditions from our partner financial institutions, which will
											also describe their processing of your personal information connected with the use of your
											Account.
										</li>
										<li>
											If you establish an investment account through the Services, we will transmit and receive
											information between Salusion and the investment institution to provide you with the Service.
										</li>
										<li>
											You may elect to receive and use a debit card to facilitate qualified purchases from your Account.
											In this case, information will be shared between the financial institution, the card issuer, the
											payment processor, and Salusion to facilitate the provision of the Service. The terms and privacy
											policy from the financial institution will provide more information on this arrangement.
										</li>
									</ul>
								</div>
								<div className="privacy-inner-child privacy-inner-child-space">
									<h6>F. Location Information</h6>
									<p>
										When you use our Site and Services, we may collect and process location information. If you do not
										want this information collected by us, you can disable location services on your phone.
									</p>
								</div>
								<div className="privacy-inner-child">
									<h6>II. Processing of Personal Information</h6>
									<p>
										We need certain Personal Information in order to provide you with access to the Site and/or the
										Services. We will only process your Personal Information in accordance with applicable data
										protection and privacy laws. If you registered with us, you will have been asked to check a tick box
										indicating your agreement to provide this data in order to access our Services and view our content.
										This consent provides us with the legal basis we require under applicable law to process your
										Personal Information. You maintain the right to withdraw such consent at any time. If you do not
										agree to our use of your Personal Information in line with this policy, please do not use our Site
										or Services.
									</p>
								</div>
								<div className="privacy-inner-child">
									<h6>A. Use of Your Personal Information</h6>
									<p>
										We use and retain your Personal Information in order to provide products and services and may, from
										time to time, provide such information to nonaffiliated third party service providers to perform
										services for or functions on behalf of us, to effect, administer, or enforce transactions authorized
										by you, including:
									</p>
									<ul>
										<li>To detect, analyze, and prevent security incidents.</li>
										<li>To audit our Site's performance.</li>
										<li>
											To identify, debug, and repair any errors that impair existing intended functionality. In order to
											deliver the Services to you, we will also use your information:
										</li>
										<ul>
											<li>
												To verify your identity, open and administer your Accounts, and provide other financial services
												under the USA PATRIOT Act
											</li>
											<li>
												To link accounts you provide us to facilitate the movement of funds for savings, investment
												accounts, and payments, as directed by you
											</li>
											<li>To communicate with you about your Account(s) and the Services</li>
											<li>To make payments to medical service providers</li>
											<li>To provide you with any health insurance information, if applicable</li>
											<li>To help us protect you and us from financial loss</li>
											<li>To prepare account statements and</li>
											<li>To prepare annual tax reporting information, if applicable.</li>
										</ul>
										<li>To enable us to process, validate, verify and deliver Services that You have ordered.</li>
										<li>
											To allow us or You to personalize the Services that You obtain based on personal characteristics
											or preferences.
										</li>
										<li>
											To provide Service Notices to You about important changes to the Website or the Application.
										</li>
										<li>
											To provide information about new or existing products, services or special offers from us or from
											other companies we believe will be of interest to You or otherwise to contact You.
										</li>
										<li>To improve Salusion's Services.</li>
										<li>
											To monitor Your compliance with the terms of the Salusion Terms of Use. For example, we
											automatically collect IP address information from You when You visit our Website, and we may use
											this information to monitor Your compliance with the restrictions in our Terms of Use regarding
											sharing access to Services.
										</li>
										<li>To comply with the law.</li>
									</ul>
									<p>In addition, we will collect and store the following:</p>
									<ul>
										<li>Names and other identifying information of the dependents that are covered by your Account</li>
										<li>Names and other identifying information of people authorized by you to use your Account</li>
										<li>
											Names and other identifying information of people authorized by you to access your Account and
										</li>
										<li>
											Transactions with us such as your Account balance, fees, payments, withdrawals,
											deposits/contributions, and the identity of persons to whom you make payments, including health
											care providers.
										</li>
									</ul>
								</div>
								<div className="privacy-inner-child">
									<h6>B. User Testimonials and Feedback</h6>
									<p>
										We often receive testimonials and comments from users who have had positive experiences with our
										Services. We occasionally publish such content. If we publish this content, we may identify our
										users by their first and last name and may also indicate their home city with the user's consent. We
										may share your feedback with your first name and last initial only. If you make any comments on a
										blog or forum associated with our Site, you should be aware that any Personal Information you submit
										there can be read, collected, or used by other users of these forums, and could be used to send you
										unsolicited messages. We are not responsible for the personally identifiable information you choose
										to submit in these blogs and forums.
									</p>
								</div>
								<div className="privacy-inner-child">
									<h6>C. Anonymous Data</h6>
									<p>
										We may create Anonymous Data records from Personal Information by excluding information (such as
										your name) that makes the data personally identifiable to you. We use this Anonymous Data to analyze
										request and usage patterns so that we may enhance the content of our Services and improve Site
										navigation. We reserve the right to use Anonymous Data and aggregated and other de-identified
										information for any purpose and disclose Anonymous Data to third parties in our sole discretion.
									</p>
								</div>
								<div className="privacy-inner-child">
									<h6>III. How Salusion Shares Your Personal Information</h6>
									<p>
										We disclose your Personal Information as described below and as described elsewhere in this Privacy
										Policy.
									</p>
								</div>
								<div className="privacy-inner-child privacy-inner-child-space">
									<h6>A. Third Parties Designated by You</h6>
									<p>
										When you use the Services, the Personal Information you provide will be shared with third parties
										that you or your employer designate to receive such information. Depending on the type of access you
										or your employer grant to such third parties, they may also be permitted to edit the information you
										have provided to us and to designate others to access and edit such information. You may change your
										settings at any time as to who has access to your information by contacting us at
										<a href="mailto:support@salusion.com" target="_top">
											support@salusion.com
										</a>
										.
									</p>
								</div>
								<div className="privacy-inner-child privacy-inner-child-space">
									<h6>B. Employers</h6>
									<p>
										We will share your Personal Information with your employer solely for the purpose of providing the
										Services.
									</p>
								</div>
								<div className="privacy-inner-child privacy-inner-child-space">
									<h6>C. Third Party Service Providers</h6>
									<p>
										We may share your Personal Information with third party service providers, including reputable
										reference sources or reporting agencies, to: provide you with the Services that we offer you through
										our Site to conduct quality assurance testing to facilitate creation of accounts to provide
										technical support for risk management and to maximize the accuracy of your information and/or to
										provide other services to Salusion. These third party service providers are required not to use your
										Personal Information other than to provide the services requested by Salusion.
									</p>
								</div>
								<div className="privacy-inner-child privacy-inner-child-space">
									<h6>D. Affiliates</h6>
									<p>
										We may share some or all of your Personal Information with our parent company, subsidiaries, joint
										ventures, or other companies under a common control ("Affiliates"), in which case we will require
										our Affiliates to honor this Privacy Policy.
									</p>
								</div>
								<div className="privacy-inner-child privacy-inner-child-space">
									<h6>E. Corporate Restructuring</h6>
									<p>
										We may share some or all of your Personal Information in connection with or during negotiation of
										any merger, financing, acquisition, or dissolution transaction or proceeding involving sale,
										transfer, divestiture, or disclosure of all or a portion of our business or assets. In the event of
										an insolvency, bankruptcy, or receivership, Personal Information may also be transferred as a
										business asset. If another company acquires our company, business, or assets, that company will
										possess the Personal Information collected by us and will assume the rights and obligations
										regarding your Personal Information as described in this Privacy Policy.
									</p>
								</div>
								<div className="privacy-inner-child privacy-inner-child-space">
									<h6>F. Disclosure to Third Party Companies</h6>
									<p>
										We may enter into agreements with companies that provide our Services by way of a co-branded or
										private-labeled website or companies that offer their products and/or services on our website or
										enter into strategic alliances or partnerships with third parties for the purposes of jointly
										providing additional services. ("Third Party Companies"). A Third Party Company may want access to
										Personal Information that we collect from its customers. As a result, we may disclose your Personal
										Information to a Third Party Company partners involved in the provision of our Services. The privacy
										policies of these Third Party Companies may apply to the use and disclosure of your Personal
										Information that we collect and disclose to such Third Party Companies. Because we do not control
										the privacy practices of Third Party Companies, you should read and understand their privacy
										policies.
									</p>
								</div>
								<div className="privacy-inner-child privacy-inner-child-space">
									<h6>G. Other Disclosures</h6>
									<p>
										Regardless of any choices you make regarding your Personal Information (as described below),
										Salusion may disclose Personal Information if it believes in good faith that such disclosure is
										necessary (a) in connection with any legal investigation (b) to comply with relevant laws or to
										respond to subpoenas or warrants served on Salusion (c) to protect or defend the rights or property
										of Salusion or users of the Site or Services and/or (d) to investigate or assist in preventing any
										violation or potential violation of the law, this Privacy Policy, or our Terms of Use.
									</p>
								</div>
								<div className="privacy-inner-child privacy-inner-child-space">
									<h6>H. Third Party Websites</h6>
									<p>
										Our Site may contain links to third party websites. When you click on a link to any other website or
										location, you will leave our Site and go to another site, and another entity may collect Personal
										Information or Anonymous Data from you. We have no control over, do not review, and cannot be
										responsible for, these outside websites or their content. Please be aware that the terms of this
										Privacy Policy do not apply to these outside websites or content, or to any collection of your
										Personal Information after you click on links to such outside websites. We encourage you to read the
										privacy policies of every website you visit. The links to third party websites or locations are for
										your convenience and do not signify our endorsement of such third parties or their products,
										content, or websites.
									</p>
								</div>
								<div className="privacy-inner-child">
									<h6>I. No Sales</h6>
									<p>
										Salusion does not engage in the sale of personal information. We have not engaged in the sale of
										personal information in the past 12 months and do not anticipate doing so moving forward.
									</p>
								</div>
								<div className="privacy-inner-child">
									<h6>IV. Exercising Your Rights</h6>
									<p>
										Consistent with our belief that you are in control of how your personal information is used and
										shared, Salusion supports a full array of data subject rights. To exercise any of these rights, you
										may contact Salusion through any of the following means:
									</p>
									<div>
										<p>
											Email:{" "}
											<a href="mailto:support@salusion.com" target="_top">
												support@salusion.com
											</a>
										</p>
										<p>Postal mail: Salusion, Inc.</p>
										<p>Attn: Legal</p>
										<p>415 Madison Ave, 4th Floor</p>
										<p>New York, NY 10017</p>
									</div>
								</div>
								<div className="privacy-inner-child">
									<h6>A. Right of Access</h6>
									<p>
										You can access your account through the Site and view and update your personal information. Users
										may make changes to their Personal Information through their Accounts. For Personal Information that
										cannot be changed via your Account, you may contact us at
										<a href="mailto:support@salusion.com" target="_top">
											support@salusion.com
										</a>{" "}
										to request the change. We will use commercially reasonable efforts to honor your requests. If you
										have questions about what Personal Information Salusion holds about you, you can contact us at
										<a href="mailto:support@salusion.com" target="_top">
											support@salusion.com
										</a>{" "}
										or through any of the other methods described above.
									</p>
								</div>
								<div className="privacy-inner-child">
									<h6>B. Right to Deletion</h6>
									<p>
										You may request deletion of your personal information. We may retain an archived copy of your
										records as required by law, to continue to provide you with the Services, or for other legitimate
										business purposes. If you completely delete all of your Personal Information, then your Account may
										become deactivated.
									</p>
								</div>
								<div className="privacy-inner-child">
									<h6>C. Right to Non-Discrimination</h6>
									<p>Salusion will never discriminate against you for exercising any of the above-described rights.</p>
								</div>
								<div className="privacy-inner-child">
									<h6>D. Other Choices Regarding Information</h6>
									<p>
										In addition to the above rights, you have the following choices regarding the use of information on
										our Service:
									</p>
									<ul>
										<li>
											Email Communications. We will periodically send you free newsletters and emails that directly
											promote the use of our Site or Services. When you receive newsletters or promotional
											communications from us, you may indicate a preference to stop receiving further communications
											from us, and you will have the opportunity to "opt out" by following the unsubscribe instructions
											provided in the email you receive or by contacting us directly (please see contact information
											above). Despite your indicated email preferences, we may send you service-related communications,
											including notices of any updates to our Terms of Use or Privacy Policy.
										</li>
										<li>
											Cookies. If you decide at any time that you no longer wish to accept Cookies from our Service for
											any of the purposes described above, then you can instruct your browser, by changing its settings,
											to stop accepting Cookies or to prompt you before accepting a Cookie from the websites you visit.
											Consult your browser's technical information. If you do not accept Cookies, however, you may not
											be able to use all portions of the Service or all functionality of the Service. If you have any
											questions about how to disable or modify Cookies, please let us know at the contact information
											provided above.
										</li>
									</ul>
								</div>
								<div className="privacy-inner-child">
									<h6>V. About the Services</h6>
								</div>
								<div className="privacy-inner-child">
									<h6>A. No Users Under Age 13</h6>
									<p>
										The Services are not intended for children. We do not intentionally gather Personal Information from
										visitors who are under the age of 13. If a child under 13 submits Personal Information to Salusion
										and we learn that the Personal Information is the information of a child under 13, we will attempt
										to delete the information as soon as possible. If you believe that we might have any Personal
										Information from a child under 13, please contact us at
										<a href="mailto:support@salusion.com" target="_top">
											support@salusion.com
										</a>
										.
									</p>
								</div>
								<div className="privacy-inner-child">
									<h6>B. Users Outside the United States</h6>
									<p>
										The Site and Services allow you to manage accounts established under and governed by U.S. tax law.
										The Site and Services are not intended for data subjects in the European Union. If you are a
										non-U.S. user of the Site, by visiting the Site and providing us with data, you acknowledge and
										agree that your Personal Information may be processed for the purposes identified in the Privacy
										Policy. In addition, your Personal Information may be processed in the country in which it was
										collected and in other countries, including the United States, where laws regarding processing of
										Personal Information may be less stringent than the laws in your country.
									</p>
								</div>
								<div className="privacy-inner-child">
									<h6>C. Security of Personal Information and Bank Information</h6>
									<p>
										We have developed policies and procedures to keep your Personal Information confidential and secure.
										We restrict access to those employees and other persons who must use that information to provide
										services on our behalf. We maintain physical, electronic, and procedural safeguards, in compliance
										with applicable laws, regulations, and industry standards, to protect the confidentiality of the
										Personal Information we obtain. We require our service providers to maintain the same level of
										confidentiality and security that we do. We continually update and improve our security standards
										and procedures to help protect against anyone gaining unauthorized access to your Personal
										Information and to prevent fraud.
									</p>
								</div>
								<div className="privacy-inner-child">
									<h6>D. Changes to This Privacy Policy</h6>
									<p>
										This Privacy Policy is subject to occasional revision, and if we make any material changes in the
										way we use your Personal Information, we will notify you by prominently posting notice of the
										changes on our Site and in some cases by sending you an email to the last email address you provided
										to us. These changes will be effective immediately for new users of our Service, and details about
										when the changes take effect for existing users will be included in the communication about the
										changes. Continued use of our Site or Service following notice of such changes shall indicate your
										acknowledgement of such changes and agreement to be bound by the terms and conditions of such
										changes.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
