import { Helmet } from "react-helmet-async"

import CreateAccountHero from "../components/Create-Account/CreateAccountHero"
import "../components/Create-Account/CreateAccount.css"

export default function CreateAccount() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "Product",
		"name": "Set Up Your HRA in Minutes | Simple & Affordable | Salusion",
		"description": "Salusion makes it easy to setup and administer an HRA.  Affordable, IRS-compliant, and hassle-free. Offer tax-free health benefits today!",
		"brand": {
			"@type": "Brand",
			"name": "Salusion"
		},
		"offers": [
			{
				"@type": "Offer",
				"name": "ICHRA",
				"description": "Individual Coverage Health Reimbursement Arrangement (ICHRA)",
				"price": "14.00",
				"priceCurrency": "USD",
				"availability": "https://schema.org/InStock",
				"priceSpecification": {
					"@type": "UnitPriceSpecification",
					"price": "14.00",
					"priceCurrency": "USD",
					"billingDuration": 1,
					"billingIncrement": 1,
					"billingPeriod": "http://purl.org/goodrelations/v1#Month",
					"unitText": "participant/month",
				},
				"eligibleQuantity": {
					"@type": "QuantitativeValue",
					"value": 1,
					"unitText": "participant"
				},
				"shippingDetails": {
					"@type": "OfferShippingDetails",
					"shippingRate": {
						"@type": "MonetaryAmount",
						"value": "0.00",
						"currency": "USD"
					},
					"shippingDestination": {
						"@type": "DefinedRegion",
						"name": "United States"
					},
					"deliveryTime": {
						"@type": "QuantitativeValue",
						"value": "0",
						"unitCode": "DAY"
					}
				}
				},
			{
				"@type": "Offer",
				"name": "QSEHRA",
				"description": "Qualified Small Employer Health Reimbursement Arrangement (QSEHRA)",
				"price": "14.00",
				"priceCurrency": "USD",
				"availability": "https://schema.org/InStock",
				"priceSpecification": {
					"@type": "UnitPriceSpecification",
					"price": "14.00",
					"priceCurrency": "USD",
					"billingDuration": 1,
					"billingIncrement": 1,
					"billingPeriod": "http://purl.org/goodrelations/v1#Month",
					"unitText": "participant/month",
				},
				"eligibleQuantity": {
					"@type": "QuantitativeValue",
					"value": 1,
					"unitText": "participant"
				},
			},
			{
				"@type": "Offer",
				"name": "GCHRA",
				"description": "Group Coverage Health Reimbursement Arrangement (ICHRA)",
				"price": "5.00",
				"priceCurrency": "USD",
				"availability": "https://schema.org/InStock",
				"priceSpecification": {
					"@type": "UnitPriceSpecification",
					"price": "5.00",
					"priceCurrency": "USD",
					"billingDuration": 1,
					"billingIncrement": 1,
					"billingPeriod": "http://purl.org/goodrelations/v1#Month",
					"unitText": "participant/month",
				},
				"eligibleQuantity": {
					"@type": "QuantitativeValue",
					"value": 1,
					"unitText": "participant"
				},
			},
			{
				"@type": "Offer",
				"name": "EBHRA",
				"description": "Excepted Benefit Health Reimbursement Arrangement (ICHRA)",
				"price": "5.00",
				"priceCurrency": "USD",
				"availability": "https://schema.org/InStock",
				"priceSpecification": {
					"@type": "UnitPriceSpecification",
					"price": "5.00",
					"priceCurrency": "USD",
					"billingDuration": 1,
					"billingIncrement": 1,
					"billingPeriod": "http://purl.org/goodrelations/v1#Month",
					"unitText": "participant/month",
				},
				"eligibleQuantity": {
					"@type": "QuantitativeValue",
					"value": 1,
					"unitText": "participant"
				},
			},
		],
		"image": "https://salusion.com/assets/images/homepage/reimbursement.svg",
		"url": "https://www.salusion.com/product/gchra",
		"datePublished": "2024-10-05T14:30:00+00:00",
		"dateModified": "2024-10-05T14:30:00+00:00",
	}

	return (
		<>
			<Helmet>
				<title>Set Up Your HRA in Minutes | Simple & Affordable | Salusion</title>
				<meta name="title" content="Set Up Your HRA in Minutes | Simple & Affordable | Salusion" />
				<meta name="description" content="Salusion makes it easy to setup and administer an HRA.  Affordable, IRS-compliant, and hassle-free. Offer tax-free health benefits today!" />
				<meta name="keywords" content="HRA setup, administration, step-by-step instructions" />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>
			<CreateAccountHero />
		</>
	)
}
